// libs
import React from "react"
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from "react-router-dom";

import '../../node_modules/bootstrap-icons/font/bootstrap-icons.scss'

// app
import App from "./App"
import { store } from './Store'

const root = createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>
)