// libs
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { sub, add, format } from 'date-fns'

// app
import { client } from '../../api/client'
import { URL, DOC, SCHED, SCHED_LOCATION, cell } from "../../helpers/coda-api"

const _fetchSchedules = createAsyncThunk(
    'schedules/fetch',
    async () => {
        const res = await client.get(URL + "/" + DOC.SCHED.id + "/tables/" + DOC.SCHED.tables.SCHEDULES + "/rows")
        return res
    }
)

const _fetchLocations = createAsyncThunk(
    'schedules/fetchLocations',
    async () => {
        const res = await client.get(URL + "/" + DOC.SCHED.id + "/tables/" + DOC.SCHED.tables.LOCATIONS + "/rows")
        return res
    }
)

const _RANGE = {
    currWeek: 'Current Week',
    prevWeek: 'Previous Week',
    nextWeek: 'Next Week',
    custom: 'Custom'
}


const _filterData = (payload, params) => {
    const { dateRange = '', sort = 'name' } = params || {}
    console.log(`FILTER schedule${dateRange ? ' for ' + dateRange + ' by ' + sort : ''}`)

    // -- date ranges
    const _today = new Date()
    
    // unlike mileage, our week starts on Monday for schedule 
    // `getDay()` SUNDAY = 0
    let _selectedWeekOf = sub(_today, { days: (_today.getDay() > 0 ? _today.getDay() - 1 : -1) })

    switch (dateRange) {
        case _RANGE.prevWeek:
            _selectedWeekOf = sub(_selectedWeekOf, { weeks: 1 })
            break
        case _RANGE.nextWeek:
            _selectedWeekOf = add(_selectedWeekOf, { weeks: 1 })
            break
        case _RANGE.custom:
            break
        case _RANGE.currWeek:
        default:
            break
    }

    // NOTE: takes normalized data, so must first convert to an array for sorting and filtering
    const arr = payload.allIds.map(id => { return payload.byId[String(id)] })
    return arr.reduce((prev, row, idx) => {

        // only want to compare dates (not times)
        if (format(new Date(cell(row, SCHED.weekOf)), 'MMM dd, yyyy') === format(_selectedWeekOf, 'MMM dd, yyyy')) prev.entries.push(row)

        if (sort === 'job') prev.entries.sort((a, b) => { return cell(a, SCHED.monday) > cell(b, SCHED.monday) ? 1 : -1 })
        else prev.entries.sort((a, b) => { return cell(a, SCHED.name) > cell(b, SCHED.name) ? 1 : -1 })

        // [1] flatten all of the current entries into one array
        const _jobsOnly = prev.entries.map(item => {
            return Array(
                cell(item, SCHED.monday),
                cell(item, SCHED.tuesday),
                cell(item, SCHED.wednesday),
                cell(item, SCHED.thursday),
                cell(item, SCHED.friday)
            )
        }).flat()
        // [2] find the unique jobs (must begin with 4 numbers; no "K2", "SHOP", etc)
        prev.unique = _jobsOnly.filter((val, idx, arr) => { return val.match(/^[0-9]{4}/) && arr.indexOf(val) === idx })

        return prev
    }, {
        sort: sort || 'name',
        dateRange: { name: dateRange, dateStart: format(_selectedWeekOf, 'MMM dd, yyyy') },
        fiteredBy: dateRange,
        entries: [],
        unique: []
    })

}

const _filterLocationData = (payload, params) => {
    const { dateRange = '' } = params || {}
    console.log(`FILTER locations${dateRange ? ' for ' + dateRange : ''}`)

    // NOTE: takes normalized data, so must first convert to an array for sorting and filtering
    const arr = payload.allIds.map(id => { return payload.byId[String(id)] })
    return arr.reduce((prev, row, idx) => {

        // NOTE: switching off of hard-coded text in Coda
        // default "Locations" = currWeek
        let _selected = "Locations"

        switch (dateRange) {
            case _RANGE.prevWeek:
                _selected = "Locations (prev)"
                break
            case _RANGE.nextWeek:
                _selected = "Locations (next)"
                break
            case _RANGE.currWeek:
            default:
                break
        }
        // only add for selected week and if an entry exists
        if (cell(row, SCHED_LOCATION.groupBy) === _selected && cell(row, SCHED_LOCATION.name).trim() !== "") prev.push(row)
        prev.sort((a, b) => { return cell(a, SCHED_LOCATION.name) > cell(b, SCHED_LOCATION.name) ? 1 : -1 })

        return prev
    }, [])
}

// ---
// STATE
// ---
const initialState = {
    // api is loaded data
    api: {
        status: '',
        allIds: [],
        byId: {}
    },
    // so much extra code just to track form validation... ugh
    session: {
        errors: []
    },
    filtered: {
        sort: '',
        dateRange: { name: '' },
        filteredBy: '',
        entries: [],
        unique: []
    },
    // locations is also loaded data via an api
    // we are essentially mashing the core schedule data (api, session, and filtered) into one object here for locations
    // this also means that `_normalizeData()` and other methods are not generic but specific to schedules (not locations)
    locations: {
        status: '',
        allIds: [],
        byId: {},
        filtered: []
    }

}

// ---
// DATA
// ---
const _normalizeData = payload => {
    return payload.items.reduce((prev, item, idx) => {
        // remove emoji
        item.values[SCHED.monday] = item.values[SCHED.monday].replace(/(⚠|↪|ℹ️) /ig, "")
        item.values[SCHED.tuesday] = item.values[SCHED.tuesday].replace(/(⚠|↪|ℹ️) /ig, "")
        item.values[SCHED.wednesday] = item.values[SCHED.wednesday].replace(/(⚠|↪|ℹ️) /ig, "")
        item.values[SCHED.thursday] = item.values[SCHED.thursday].replace(/(⚠|↪|ℹ️) /ig, "")
        item.values[SCHED.friday] = item.values[SCHED.friday].replace(/(⚠|↪|ℹ️) /ig, "")
        // ---
        prev.allIds.push(item.id)
        prev.byId[item.id] = item
        return prev
    }, { allIds: [], byId: {} })
}

const _normalizeLocationData = payload => {
    return payload.items.reduce((prev, item, idx) => {
        prev.allIds.push(item.id)
        prev.byId[item.id] = item
        return prev
    }, { allIds: [], byId: {} })
}

/*
// @todo: is this logic more appropriate for checking our dates vs the filterScheduleData() below?
const _thisWeekSchedule = payload => {
    return payload.items.filter(item => {
        const schedDate = cell(item, SCHED.weekOf) ? new Date(cell(item, SCHED.weekOf)) : 0
        return schedDate > sub(new Date(), { days: 7 })
    }).sort((a, b) => {
        if (cell(a, SCHED.weekOf) > cell(b, SCHED.weekOf)) return 1
        if (cell(b, SCHED.weekOf) > cell(a, SCHED.weekOf)) return -1
        return 0
    })
}
*/

export const scheduleSlice = createSlice({
    name: 'schedules',

    initialState,

    // reducers take current state and action and return a new state
    reducers: {
        updateSession(state, action) {
            state.session = {
                ...state.session,
                errors: action.payload
            }
        },

        filterScheduleData(state, action) {
            // when calling _filterData() as a reducer, we need to use a {payload, params} object
            state.filtered = _filterData(
                // - payload
                {
                    // redux toolkit function for getting state instead of Proxy
                    allIds: current(state.api.allIds),
                    byId: current(state.api.byId)
                },
                // - params
                {
                    dateRange: action.payload.dateRange,
                    sort: action.payload.sort
                }
            )
        },

        filterLocations(state, action) {
            // when calling _filterLocationData() as a reducer, we need to use a {payload, params} object
            // !! NOTE: here we are one level deep in state object (locations.filtered) - our reducer returns an array which will directly populate this level of state object
            state.locations.filtered = _filterLocationData(
                // - payload
                {
                    // redux toolkit function for getting state instead of Proxy
                    allIds: current(state.locations.allIds),
                    byId: current(state.locations.byId)
                },
                // - params
                {
                    dateRange: action.payload.dateRange
                }
            )
        },

    },

    // createAsyncThunk defines actions outside of createSlice() call,
    // extraReducers allows us to listen to other action types defined elsewhere
    extraReducers: builder => {
        builder
            // -- FETCH SCHEDULE
            .addCase(_fetchSchedules.pending, (state, action) => { state.api.status = 'pending' })
            .addCase(_fetchSchedules.fulfilled, (state, action) => {

                // core item data
                const normalized = _normalizeData(action.payload)
                state.api = { status: 'fulfilled', ...normalized }
                state.filtered = _filterData(normalized, { dateRange: RANGE.currWeek })

            })
            .addCase(_fetchSchedules.rejected, (state, action) => {
                state.api.status = 'rejected'
            })
            // -- FETCH LOCATIONS
            .addCase(_fetchLocations.pending, (state, action) => { state.locations.status = 'pending' })
            .addCase(_fetchLocations.fulfilled, (state, action) => {

                // location data
                const normalized = _normalizeLocationData(action.payload)
                state.locations = { status: 'fulfilled', ...normalized }
                state.locations.filtered = _filterLocationData(normalized, { dateRange: RANGE.currWeek })

            })
            .addCase(_fetchLocations.rejected, (state, action) => {
                state.locations.status = 'rejected'
            })
    }
})

// Action creators are generated for each case reducer function
// "createSlice will automatically generate action creators" - https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
export const { updateSession, filterScheduleData, filterLocations } = scheduleSlice.actions
export const fetch = _fetchSchedules
export const fetchLocations = _fetchLocations
export const RANGE = _RANGE

export default scheduleSlice.reducer