// libs
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, Link, useSearchParams } from "react-router-dom"
import { format, compareDesc } from 'date-fns'

// app
import { fetch, filterMillSheets } from './millSheetsSlice'
import { MILL, cell } from "../../helpers/smartsheet-api"


export function MillSheetsList() {

    // searchParams = query params
    // @todo: updated this to use queryParams (better name) in ReceivingNoticesList, should do same here
    let [searchParams, setSearchParams] = useSearchParams()

    // local state only
    // @todo: moved from local state to app state in ReceivingNoticesList, should do same here
    // -- NOTE: be sure to update slice with new searchTerm and searchField values (incl. initialization)
    const [searchTerm, updateSearchTerm] = useState("")
    const [listSort, updateListSort] = useState("date")

    // get data from store
    //const apiData = useSelector((state) => state.millSheets.api)
    const apiStatus = useSelector((state) => state.millSheets.api.status)
    const apiTotal = useSelector((state) => state.millSheets.api.allIds).length

    const attachmentsListData = useSelector((state) => state.millSheets.attachmentsList)
    const millSheetsSubmitted = useSelector((state) => state.millSheets.filtered.submitted)
    const millSheetsCompleted = useSelector((state) => state.millSheets.filtered.completed)
    // how to dispatch actions
    const dispatch = useDispatch()

    // 2 days and 7 days old respectively
    const _lateShipDate = new Date(Date.now() - (2 * 24 * 60 * 60 * 1000))
    const _reallyLateShipDate = new Date(Date.now() - (8 * 24 * 60 * 60 * 1000))

    // ------
    // HANDLERS
    // ------
    const onSearchChange = (evt) => {
        evt.preventDefault()

        const searchTerm = evt.target.value && evt.target.value.trim() ? evt.target.value.trim() : ''
        const doSearch = searchTerm.length > 2 || searchTerm.length === 0

        updateSearchTerm(searchTerm)

        console.log("dispatch filterMillSheets:", doSearch && searchTerm)
        if (doSearch) dispatch(filterMillSheets({ sort: listSort, searchTerm: searchTerm, searchField: 'job' }))
    }

    const onDateSort = (evt) => {
        evt.preventDefault()
        updateListSort("date")
        dispatch(filterMillSheets({
            sort: "date",
            searchTerm: searchTerm || '', searchField: 'job'
        }))
    }

    const onJobSort = (evt) => {
        evt.preventDefault()
        updateListSort("job")
        dispatch(filterMillSheets({
            sort: "job",
            searchTerm: searchTerm || '', searchField: 'job'
        }))
    }
    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {

        // NOTE: if data has not been pulled (ie. !== fulfilled), then fetch
        // @todo: update this code to be more clean like MileageEntry.js (ie. _apiData.status vs apiStatus)
        apiStatus !== 'fulfilled' && dispatch(fetch())

        // if passes the ?millSheetIsCompleted param to this route, reload our millSheets
        if (searchParams && searchParams.get('millSheetIsCompleted')) {
            setSearchParams({ millSheetIsCompleted: false })
            dispatch(fetch())
        }
        // NOTE the [] as the second param, this ensures we only run useEffect() once
    }, []);

    // ------
    // RENDER
    // ------
    return (
        <div>

            <Outlet />

            {/* action bar */}
            {(apiStatus === '' || apiStatus == 'rejected') &&
                <button
                    className='btn btn-primary'
                    aria-label="load"
                    onClick={() => dispatch(fetch())}
                >
                    <i className='bi-cloud-download'></i> Get mill sheets
                </button>
            }

            {/* SHEETDATA | can we re-use this */}
            {apiStatus === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading <strong>mill sheets</strong>...</div>}

            {apiStatus === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to load <strong>mill sheets</strong>.</div>}

            {apiStatus === 'fulfilled' &&
                <div className='sticky-top pt-2'>

                    {/* SEARCH ui */}
                    <form className="mb-2 px-2 d-flex gap-2 justify-content-between align-items-center" role="search">
                        <input type="search" onChange={onSearchChange} className="form-control" value={searchTerm} placeholder="Search..." aria-label="Search" />

                        { /* show CLEAR only when searching... */
                            millSheetsSubmitted.length + millSheetsCompleted.length < apiTotal &&
                            <button
                                className='d-flex flex-nowrap btn btn-success'
                                aria-label="load"
                                onClick={() => {
                                    updateSearchTerm('')
                                    dispatch(filterMillSheets({
                                        searchTerm: '',
                                        searchField: 'vendor'
                                    }))
                                }}
                            >
                                <i className='bi-x-circle'></i>&nbsp;Clear
                            </button>
                        }

                        {/* ...show DROPDOWN when not searching */
                            millSheetsSubmitted.length + millSheetsCompleted.length >= apiTotal &&
                            <select className="form-select w-50 ms-2" aria-label="select search type">
                                <option defaultValue="job">job</option>
                                {/*<option value="job">job</option>*/}
                            </select>
                        }
                    </form>

                    {/* SORT ui */}
                    <div className='px-2 d-flex gap-2 justify-content-between align-items-center'>
                        <button
                            className={'btn ' + (listSort === 'date' ? 'btn-dark' : 'btn-outline-dark border-2')}
                            aria-label="sort by date"
                            onClick={onDateSort}
                        >
                            date
                        </button>
                        <button
                            className={'btn ' + (listSort === 'job' ? 'btn-dark' : 'btn-outline-dark border-2')}
                            aria-label="sort by date"
                            onClick={onJobSort}
                        >
                            job
                        </button>
                        <div className='flex-grow-1 text-center'>Showing<br />{millSheetsSubmitted.length + millSheetsCompleted.length} of {apiTotal}</div>
                        <button
                            className='btn btn-outline-dark border-2'
                            aria-label="load"
                            onClick={() => dispatch(fetch())}
                        >
                            <i className='bi-arrow-clockwise'></i>
                        </button>
                    </div>
                </div>
            }

            {/* ATTACHMENTS | can we re-use this */}
            {attachmentsListData.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to load <strong>attachments</strong>.</div>}

            {/* TABS ui */}
            {apiStatus === 'fulfilled' && <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="submitted-tab" data-bs-toggle="tab" data-bs-target="#submitted-tab-content" type="button" role="tab" aria-controls="submitted" aria-selected="true"><i className='bi-clipboard'></i> submitted</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="completed-tab" data-bs-toggle="tab" data-bs-target="#completed-tab-content" type="button" role="tab" aria-controls="completed" aria-selected="false"><i className='bi-clipboard-check'></i> completed</button>
                </li>
            </ul>}


            {/* SUBMITTED ----- */}
            <div className="tab-content" id="noticesTabContent">
                <div className="tab-pane fade show active" id="submitted-tab-content" role="tabpanel" aria-labelledby="submitted-tab">
                    {apiStatus === 'fulfilled' &&
                        millSheetsSubmitted.map((millsheet, idx) => {
                            return <div className='my-2 pb-2 border-bottom border-2' key={idx}>
                                <div className='d-flex gap-2 justify-content-between align-items-center'>
                                    <div>
                                        <div className='fw-bold'>{cell(millsheet, MILL.workSummary)}</div>
                                        {cell(millsheet, MILL.assignee) && cell(millsheet, MILL.assignee).trim() !== '' && <div className='text-dark'>{cell(millsheet, MILL.assignee)}</div>}
                                    </div>
                                    <div>
                                        <Link
                                            className='btn btn-primary btn-sm'
                                            to={`/mill-sheets/${millsheet.id}`}
                                            key={millsheet.id}
                                        >
                                            Complete <i className='bi-arrow-right-short'></i>
                                        </Link>
                                    </div>
                                </div>


                                <table className="mt-2 table table-sm table-borderless">
                                    <tbody>
                                        <tr className="border-top">
                                            <td className="label-data w-25">ORDERED</td>
                                            <td className={compareDesc(new Date(cell(millsheet, MILL.workOrdered) + "T00:00:00"), _reallyLateShipDate) === 1 && listSort === "date"
                                                    ? 'fw-bold text-danger' : compareDesc(new Date(cell(millsheet, MILL.workOrdered) + "T00:00:00"), _lateShipDate) === 1 && listSort === "date"
                                                        ? 'fw-bold text-warning' : listSort === "date"
                                                            ? 'fw-bold text-success' : ''}>
                                                {format(new Date(cell(millsheet, MILL.workOrdered) + "T00:00:00"), "eee, LLL d")}
                                            </td>
                                        </tr>
                                        {cell(millsheet, MILL.completeTarget) && <tr className="border-top">
                                            <td className="label-data">EXPECTED BY</td>
                                            <td className='fw-bold'>{format(new Date(cell(millsheet, MILL.completeTarget) + "T00:00:00"), "eee, LLL d")}</td>
                                        </tr>}
                                        <tr className="border-top">
                                            <td className="label-data">ORDERED BY</td>
                                            <td>{cell(millsheet, MILL.orderBy)}</td>
                                        </tr>
                                        <tr className="border-top">
                                            <td className="label-data">JOB</td>
                                            <td>{cell(millsheet, MILL.formJob)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        })}

                </div>

                {/* COMPLETED ----- */}
                <div className="tab-pane fade" id="completed-tab-content" role="tabpanel" aria-labelledby="completed-tab">
                    {apiStatus === 'fulfilled' &&
                        millSheetsCompleted.map((millsheet, idx) => {
                            return <div className='my-2 pb-2 border-bottom border-2' key={idx}>
                                <div className='d-flex gap-2 justify-content-between align-items-center'>
                                    <div>
                                        <div className='fw-bold'>{cell(millsheet, MILL.workSummary)}</div>
                                        {cell(millsheet, MILL.assignee) && cell(millsheet, MILL.assignee).trim() !== '' && <div className='text-dark'>{cell(millsheet, MILL.assignee)}</div>}
                                    </div>
                                    <div>
                                        <Link
                                            className='btn btn-primary btn-sm'
                                            to={`/mill-sheets/${millsheet.id}`}
                                            key={millsheet.id}
                                        >
                                            View
                                        </Link>
                                    </div>
                                </div>


                                <table className="mt-2 table table-sm table-borderless">
                                    <tbody>
                                        <tr className="border-top">
                                            <td className="label-data w-25">COMPLETED</td>
                                            <td>{format(new Date(cell(millsheet, MILL.completeDate) + "T00:00:00"), "eee, LLL d")}</td>
                                        </tr>
                                        <tr className="border-top">
                                            <td className="label-data">JOB</td>
                                            <td>{cell(millsheet, MILL.formJob)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        })}



                    {apiStatus === 'fulfilled' && millSheetsCompleted.length === 0 && <p>None completed at this time.</p>}
                </div>


            </div>

        </div>
    )
}