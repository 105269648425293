// libs
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'

// app
import { client } from '../../api/client'
import { URL, SHEET, MILL, cell } from "../../helpers/smartsheet-api"
import 'regenerator-runtime'



// @todo: why do i need to do this??
const dummy = { 't': "t" }
const _fetchMillSheets = createAsyncThunk(
  'millSheets/fetch',
  async (dummy, { dispatch }) => {
    const res = await client.get(URL + "/" + SHEET.millSheets)
    // attachments are per sheet, so might as well grab them here.
    dispatch(_fetchAttachmentsList())
    return res
  }
)

const _fetchAttachmentsList = createAsyncThunk(
  'millsheetAttachmentsList/fetch',
  async () => {
    const res = await client.get(URL + "/" + SHEET.millSheets + "/attachments")
    return res
  }
)

const _fetchAttachment = createAsyncThunk(
  'millsheetAttachment/fetch',
  async ({ sheetId, attachmentId }) => {
    const res = await client.get(URL + "/" + sheetId + "/attachments/" + attachmentId)
    return res
  }
)




const _putMillSheetUpdate = createAsyncThunk(
  'millSheets/update',

  // first param is the object sent to the _putMillSheetUpdate() method
  async ({ id, cells }, { rejectWithValue }) => {
    try {
      const res = await client.put(
        URL + "/" + SHEET.millSheets + "/rows",
        {
          id: id,
          cells: cells
        }
      )
      return res
    } catch (err) {
      //https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err)
    }
  }
)

// ---
// DATA
// ---
const _normalizeData = payload => {
  return payload.rows.reduce((prev, row, idx) => {
    prev.allIds.push(row.id)
    prev.byId[row.id] = row
    return prev
  }, { allIds: [], byId: {} })
}

const _initializeSession = payload => {
  return payload.rows.reduce((prev, row, idx) => {
    prev.byId[row.id] = {
      // status of the submission (ie. local form errors) vs. api status (problem with request)
      // NOTE: opportunity to refactor and move this information into "local state" via reach useState hook
      status: '',
      errors: [],
      data: {},
      // at row level (per millsheet)
      attachments: { status: '', data: {} },
      // @todo: better name for this?  how to track interaction with specific actions for an individual row (ie. update, etc)
      api: {
        status: '',
        error: ''
      }
    }
    return prev
  }, { byId: {} })
}


const _filterData = payload => {
  // NOTE: takes normalized data, so must first convert to an array for sorting and filtering
  const arr = payload.allIds.map(id => { return payload.byId[String(id)] })
  return arr.reduce((prev, row, idx) => {

    // [ 1 ] filter by searchTerm
    if (payload.searchTerm) {

      if (cell(row, MILL.formJob) && cell(row, MILL.formJob).match(new RegExp(payload.searchTerm, 'gi'))) {
        console.warn(`found "${cell(row, MILL.formJob)}" in "${payload.searchTerm}" --> ${cell(row, MILL.formJob) && cell(row, MILL.formJob).match(new RegExp(payload.searchTerm, 'gi'))}`)

        // [ 2 ] breakout submitted vs completed
        // @todo: this is the exact same below in else statement - DRY
        if (cell(row, MILL.completeDate) || cell(row, MILL.docStage) === 'completed') prev.completed.push(row)
        else prev.submitted.push(row)

      } else {
        //console.log(`searching "${cell(row, MILL.formJob)}" for "${payload.searchTerm}" --> ${cell(row, MILL.formJob) && cell(row, MILL.formJob).match(new RegExp(payload.searchTerm, 'gi'))}`)
      }

    } else {
      // [ 2 ] breakout submitted vs completed
      // @todo: this is the exact same above in if statement - DRY
      if (cell(row, MILL.completeDate) || cell(row, MILL.docStage) === 'completed') prev.completed.push(row)
      else prev.submitted.push(row)
    }

    // [ 3 ] sort
    // PENDING --> sort by...
    if (payload.sort && payload.sort === 'job') {
      // ...job
      prev.submitted.sort((a, b) => {
        return cell(a, MILL.formJob) > cell(b, MILL.formJob) ? 1 : -1
      })
      prev.completed.sort((a, b) => {
        return cell(a, MILL.formJob) > cell(b, MILL.formJob) ? 1 : -1
      })
    } else {
      // ...date (default)
      prev.submitted.sort((a, b) => {
        // EXPECTED date (not a required field)
        if (!cell(a, MILL.workOrdered)) return 1
        if (!cell(b, MILL.workOrdered)) return -1
        return cell(a, MILL.workOrdered) > cell(b, MILL.workOrdered) ? 1 : -1
      })

      prev.completed.sort((a, b) => {
        // RECEIVED date (newest first)
        return cell(a, MILL.completeDate) > cell(b, MILL.completeDate) ? -1 : 1
      })
    }
    return prev
  }, { sort: payload.sort || 'job', submitted: [], completed: [] })
}

// ---
// STATE
// ---
const initialState = {
  // api is loaded data
  api: {
    status: '',
    allIds: [],
    byId: {}
  },
    // at sheet level (a list of all attachments)
    attachmentsList: { status: '', data: [] },
  // session is in-memory data
  // @todo: a standard session object (errors, status, data, etc)
  session: { byId: {} },
  filtered: {
    sort: '',
    // NOTE: hardcoding these filtering options here is a tradeoff
    // -- these may need to be added to or replaced in the future
    // -- doing this, because we want React to automatically update display based on how we manipulate
    submitted: [],
    completed: []
  }
}


// ---
// SLICE
// ---
export const millSheetsSlice = createSlice({
  name: 'millSheets',

  initialState,

  // reducers take current state and action and return a new state
  reducers: {

    filterMillSheets(state, action) {
      state.filtered = _filterData({
        sort: action.payload.sort,
        searchTerm: action.payload.searchTerm,
        searchField: action.payload.searchField,
        // redux toolkit function for getting state instead of Proxy
        allIds: current(state.api.allIds),
        byId: current(state.api.byId)
      })
    },

    updateSession(state, action) {
      state.session.byId[action.payload.id] = {
        ...state.session.byId[action.payload.id],
        status: action.payload.status,
        errors: action.payload.errors,
        data: action.payload.data
      }
    }

  },

  // createAsyncThunk defines actions outside of createSlice() call,
  // extraReducers allows us to listen to other action types defined elsewhere
  extraReducers: builder => {
    builder
      // -- FETCH MILLSHEETS
      .addCase(_fetchMillSheets.pending, (state, action) => { state.api.status = 'pending' })
      .addCase(_fetchMillSheets.fulfilled, (state, action) => {

        // core row data
        const normalized = _normalizeData(action.payload)
        state.api = normalized

        // initialize session data
        state.session = _initializeSession(action.payload)

        state.filtered = _filterData(normalized)

        // add metadata
        state.api.id = action.payload.id
        state.api.name = action.payload.name
        state.api.modifiedAt = action.payload.modifiedAt
        state.api.permalink = action.payload.permalink
        state.api.status = 'fulfilled'
      })
      .addCase(_fetchMillSheets.rejected, (state, action) => {
        state.api.status = 'rejected'
        // https://smartsheet.redoc.ly/#section/Error-Code-List 
      })
      // -- FETCH ATTACHMENTS LIST (sheet level)
      .addCase(_fetchAttachmentsList.pending, (state, action) => { state.attachmentsList.status = 'pending' })
      .addCase(_fetchAttachmentsList.fulfilled, (state, action) => {
        // smartsheet attachments API returns an object with metadata properties at its root and an array of attachments as a data attribute:
        // -- { pageNumber: 1, pageSize: 100, ... data: []}
        state.attachmentsList.data = action.payload.data || []
        state.attachmentsList.status = 'fulfilled'
      })
      .addCase(_fetchAttachmentsList.rejected, (state, action) => {
        state.attachmentsList.status = 'rejected'
      })
      // -- FETCH ATTACHMENT (row level)
      .addCase(_fetchAttachment.pending, (state, action) => { state.session.byId[action.meta.arg.millsheetId].attachments.status = 'pending' })
      .addCase(_fetchAttachment.fulfilled, (state, action) => {
        state.session.byId[action.meta.arg.millsheetId].attachments.data = action.payload
        state.session.byId[action.meta.arg.millsheetId].attachments.status = 'fulfilled'
      })
      .addCase(_fetchAttachment.rejected, (state, action) => {
        state.session.byId[action.meta.arg.millsheetId].attachments.status = 'rejected'
      })
      // -- PUT
      .addCase(_putMillSheetUpdate.pending, (state, action) => { state.session.byId[action.meta.arg.id].api.status = 'pending' })
      .addCase(_putMillSheetUpdate.fulfilled, (state, action) => { state.session.byId[action.meta.arg.id].api.status = 'fulfilled' })
      .addCase(_putMillSheetUpdate.rejected, (state, action) => {
        state.session.byId[action.meta.arg.id].api.status = 'rejected'
        // using a string, can eventually use an object?
        state.session.byId[action.meta.arg.id].api.error = action.payload ? action.payload.error.errorCode + " | " + action.payload.error.message : action.error ? action.error.message : "Unable to receive notice. Unknown error."
      })
  }

})

// Action creators are generated for each case reducer function
// "createSlice will automatically generate action creators" - https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
export const { filterMillSheets, updateSession } = millSheetsSlice.actions
export const fetch = _fetchMillSheets
export const fetchAttachment = _fetchAttachment
export const update = _putMillSheetUpdate


export default millSheetsSlice.reducer
