// libs
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { sub } from 'date-fns'

// app
import { client } from '../../api/client'
import { URL, DOC, TALKS, cell } from "../../helpers/coda-api"
import { s3URL } from '../../helpers/s3-api'
import { async } from 'regenerator-runtime'

const _fetchTalks = createAsyncThunk(
    'talks/fetch',
    async () => {
        const res = await client.get(URL + "/" + DOC.SAFETY.id + "/tables/" + DOC.SAFETY.tables.TALKS + "/rows")
        return res
    }
)

const _saveSignatureImg = createAsyncThunk(
    'talks/saveSignatureImg',
    // first param is the object sent to the _saveSignatureImg() method
    async ({ imgData, fileName }) => {
        const res = await client.post(
            s3URL + "/" + "safety" + "/" + fileName,
            imgData
        )
        return res
    }
)

const _saveSignatureLog = createAsyncThunk(
    'talks/logSignature',
    // rowData is an array of objects each with a "column" and "value" property
    async ({ rowData }) => {
        try {
            const res = await client.post(
                URL + "/" + DOC.SAFETY.id + "/tables/" + DOC.SAFETY.tables.LOG + "/rows",
                {
                    rows: [{
                        cells: rowData
                    }]
                }
            )
            return res
        } catch (err) {
            //https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
            return rejectWithValue(err)
        }
    }
)


// ---
// STATE
// ---
const initialState = {
    // api is loaded data
    api: {
        status: '',
        allIds: [],
        byId: {}
    },
    // this week's talk
    currWeek: {},
    // signature img
    signature: {
        status: '',
        results: ''
    },
    // log entry (in Coda)
    signatureLog: {
        status: '',
        results: ''
    },
    // so much extra code just to track form validation... ugh
    session: {
        errors: []
    }

}

// ---
// DATA
// ---
const _normalizeData = payload => {
    return payload.items.reduce((prev, item, idx) => {
        prev.allIds.push(item.id)
        prev.byId[item.id] = item
        return prev
    }, { allIds: [], byId: {} })
}


const _thisWeekTalk = payload => {
    return payload.items.filter(item => {
        const schedDate = cell(item, TALKS.dateScheduled) ? new Date(cell(item, TALKS.dateScheduled)) : 0
        return schedDate > sub(new Date(), { days: 7 })
    }).sort((a, b) => {
        if (cell(a, TALKS.dateScheduled) > cell(b, TALKS.dateScheduled)) return 1
        if (cell(b, TALKS.dateScheduled) > cell(a, TALKS.dateScheduled)) return -1
        return 0
    })[0]
}

export const toolboxTalkSlice = createSlice({
    name: 'toolboxTalks',

    initialState,

    // reducers take current state and action and return a new state
    reducers: {
        updateSession(state, action) {
            state.session = {
                ...state.session,
                errors: action.payload
            }
        }
    },

    // createAsyncThunk defines actions outside of createSlice() call,
    // extraReducers allows us to listen to other action types defined elsewhere
    extraReducers: builder => {
        builder
            // -- FETCH MATERIAL DOCS
            .addCase(_fetchTalks.pending, (state, action) => { state.api.status = 'pending' })
            .addCase(_fetchTalks.fulfilled, (state, action) => {

                // core item data
                const normalized = _normalizeData(action.payload)
                state.api = normalized

                const thisWeek = _thisWeekTalk(action.payload)
                state.currWeek = thisWeek

                // add metadata
                state.api.status = 'fulfilled'
            })
            .addCase(_fetchTalks.rejected, (state, action) => {
                state.api.status = 'rejected'
            })
            // -- SAVE SIGNATURE
            .addCase(_saveSignatureImg.pending, (state, action) => { state.signature.status = 'pending' })
            .addCase(_saveSignatureImg.fulfilled, (state, action) => {

                // core item data
                state.signature.results = action.payload

                // add metadata
                state.signature.status = 'fulfilled'
            })
            .addCase(_saveSignatureImg.rejected, (state, action) => {
                state.signature.status = 'rejected'
            })
            // -- SAVE SIGNATURE LOG ENTRY
            .addCase(_saveSignatureLog.pending, (state, action) => { state.signatureLog.status = 'pending' })
            .addCase(_saveSignatureLog.fulfilled, (state, action) => {

                // core item data
                state.signatureLog.results = action.payload

                // add metadata
                state.signatureLog.status = 'fulfilled'
            })
            .addCase(_saveSignatureLog.rejected, (state, action) => {
                state.signatureLog.status = 'rejected'
            })
    }
})

// Action creators are generated for each case reducer function
// "createSlice will automatically generate action creators" - https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
export const { updateSession } = toolboxTalkSlice.actions
export const fetch = _fetchTalks
export const saveSignatureImg = _saveSignatureImg
export const saveSignatureLog = _saveSignatureLog

export default toolboxTalkSlice.reducer