// libs
import { CognitoUserPool } from "amazon-cognito-identity-js"

// app
const poolData = {
    UserPoolId: "us-east-1_Clv3gWLGq",
    ClientId: "60mu7t4fma0propgctr9i9f80r"
}

export default new CognitoUserPool(poolData)

