// libs
import React from 'react'
import { Outlet } from "react-router-dom";

// app


export function MillSheets(props) {


    // ------
    // RENDER
    // ------
    return (
        <div>
            <Outlet context={{user: props.user}}/>
        </div>
    )
}