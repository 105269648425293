// libs
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js"

// app
import Pool from "../../helpers/aws-userpool"
import { captureUserId } from '../../helpers/google-analytics'


// our own custom async thunks for working with AWS cognito 
// -- note the use of instantiating our own Promise
// -- authenticate is during initial login ONLY
const _authenticate = createAsyncThunk(
  'account/authenticate',
  async ({ Username, Password }) => {
    const res = await new Promise((resolve, reject) => {

      // AWS cognito
      const user = new CognitoUser({ Username, Pool })
      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        // structure of data below is based on AWS cognito
        // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-the-id-token.html
        onSuccess: data => {
          captureUserId(data.idToken.payload.email)
          resolve(data.idToken.payload)
        },
        onFailure: err => {
          reject(err)
        },
        // https://github.com/aws-amplify/amplify-js/tree/master/packages/amazon-cognito-identity-js
        // Use case 23
        // user was signed up by an admin and must provide new password, etc
        /*
          // this happened with `paulm` being added via the console
          // data obj:
          {
            "email_verified": "true",
            "name": "Paul May",
            "email": "paulm@scpb.com"
          }
        */
        newPasswordRequired: data => {
          resolve(data)
        }
      })
    })
    return res
  }
)


const _getSession = createAsyncThunk(
  'account/getSession',
  async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession((err, session) => {
          if (err) { reject(err) }
          // session is a CognitoUserSession object
          else {
            console.info("_getSession: user found")
            captureUserId(session.idToken.payload.email)
            resolve(session.idToken.payload)
          }
        })
      } else {
        console.warn("_getSession: no user found")
        reject("User session not found.")
      }
    })
  })

const _logout = createAsyncThunk(
  'account/logout',
  async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.signOut()
        resolve()
      } else {
        console.warn("_logout: no user found")
        reject("Unable to logout, no user found.")
      }
    })
  })

const _changePassword = createAsyncThunk(
  'account/changePassword',
  async ({ oldPassword, newPassword }) => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      // need to get the session again for the current user(??)
      user.getSession((err, session) => { if (err) { reject(err) } })
      if (user) {
        user.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) { reject(err) }
          else {
            console.info("_changePassword: password updated")
            resolve()
          }
        })
      } else {
        console.warn("_changePassword: no user found")
        reject("Unable to change password, no user found.")
      }
    })
  })


// https://stackoverflow.com/questions/38110615/how-to-allow-my-user-to-reset-their-password-on-cognito-user-pools
// NOTE: Cognito is particular about its variable names, ie. Username vs userName
const _getPasswordCode = createAsyncThunk(
  'account/getPasswordCode',
  async ({ Username }) => {
    return await new Promise((resolve, reject) => {

      // [ 1 ] need to "get" our user (remember he/she is not logged in, so must use SDK)
      const user = new CognitoUser({ Username, Pool })

      if (user) {
        // [ 2 ] requests a verification code from Cognito
        // - note name of user method: forgotPassword() vs getPasswordCode()
        user.forgotPassword({
          onSuccess: result => {
            resolve(result)
          },
          onFailure: err => {
            reject(err)
          },
          inputVerificationCode: data => {
            // data returns an object with "CodeDelvieryDetails"
            // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_CodeDeliveryDetailsType.html
            resolve(data.CodeDeliveryDetails)
          }
        })
      } else {
        console.warn("_getPasswordCode: no user found")
        reject()
      }
    })
  })


const _resetPassword = createAsyncThunk(
  'account/resetPassword',
  async ({ Username, verificationCode, newPassword }) => {
    return await new Promise((resolve, reject) => {

      // [ 1 ] need to "get" our user (remember he/she is not logged in, so must use SDK)
      const user = new CognitoUser({ Username, Pool })

      if (user) {
        // [ 2 ] actually reset the password
        user.confirmPassword(verificationCode, newPassword, {
          onSuccess: result => {
            console.info("_resetPassword: password updated")
            resolve(result)
          },
          onFailure: err => {
            reject(err)
          }
        })
      } else {
        console.warn("_resetPassword: no user found")
        reject()
      }
    })
  })


// ---
// DATA
// ---

/*
Multiple API interactions requiring multiple API statuses
- auth
- changePassword (already logged in)
- getPasswordCode (not logged in, need to get code before resetting)
- resetPassword (not logged in, require code)
*/
const _resetApi = () => {
  return { status: '', error: '', data: '' }
}



const _resetUser = () => {
  return {
    loggedIn: false,
    name: '',
    email: '',
    userName: ''
  }
}

const _resetAccount = () => {
  return {
    user: _resetUser(),
    auth: _resetApi(),
    changePassword: _resetApi(),
    getPasswordCode: _resetApi(),
    resetPassword: _resetApi()
  }
}

// initalization occurs after user is authenticated with API call or checking session
const _initializeUser = params => {
  return {
    loggedIn: true,
    name: params.name,
    email: params.email,
    userName: params.userName
  }
}


// ---
// STATE
// ---
const initialState = _resetAccount()


// ---
// SLICE
// ---
export const accountSlice = createSlice({
  name: 'account',

  initialState,

  // reducers take current state and action and return a new state
  reducers: {

    resetPasswordCodeStatus(state, action) {
      // action.payload is irrelevant for now
      state.getPasswordCode = _resetApi()
    }

  },
  // extraReducers allows us to listen to other action types defined elsewhere
  extraReducers: builder => {
    builder
      // AUTHENTICATE ---
      .addCase(_authenticate.pending, (state, action) => { state.auth = { ...state.auth, status: 'pending', error: '' } })
      .addCase(_authenticate.fulfilled, (state, action) => {
        state.auth = { ...state.auth, status: 'fulfilled', error: '' }
        state.user = _initializeUser({
          name: action.payload.name,
          email: action.payload.email,
          userName: action.payload['cognito:username']
        })
      })
      .addCase(_authenticate.rejected, (state, action) => {
        state.auth = {
          ...state.auth,
          status: 'rejected',
          error: action.error.message
        }
      })

      // GET SESSION ---
      // _getSession is unique.  
      // - there is no "error" (or pending) tracked in state
      // - only success changes state (the user's authentication status, same as logging in)
      .addCase(_getSession.pending, (state, action) => { })
      .addCase(_getSession.fulfilled, (state, action) => {
        state.auth = { ...state.auth, status: 'fulfilled', error: '' }
        state.user = _initializeUser({
          name: action.payload.name,
          email: action.payload.email,
          userName: action.payload['cognito:username']
        })
      })
      .addCase(_getSession.rejected, (state, action) => { })

      // LOGOUT --- (can add .pending state later if desired)
      .addCase(_logout.fulfilled, (state, action) => {
        console.info("--> logout")
        // @todo: this is same as _resetAccount(), can we not call that method?
        state.user = _resetUser(),
          state.auth = _resetApi(),
          state.changePassword = _resetApi(),
          state.getPasswordCode = _resetApi(),
          state.resetPassword = _resetApi()
      })
      .addCase(_logout.rejected, (state, action) => { console.error("unable to _logout user", action.error) })

      // CHANGE PASSWORD
      .addCase(_changePassword.pending, (state, action) => { state.changePassword = { ...state.changePassword, status: 'pending', error: '', data: '' } })
      .addCase(_changePassword.fulfilled, (state, action) => { state.changePassword = { ...state.changePassword, status: 'fulfilled', error: '', data: '' } })
      .addCase(_changePassword.rejected, (state, action) => {
        state.changePassword = {
          ...state.changePassword,
          status: 'rejected',
          error: action.error,
          data: ''
        }
      })

      // FORGOT PASSWORD
      .addCase(_getPasswordCode.pending, (state, action) => { state.getPasswordCode = { ...state.getPasswordCode, status: 'pending', error: '', data: '' } })
      .addCase(_getPasswordCode.fulfilled, (state, action) => {
        state.getPasswordCode = {
          ...state.getPasswordCode,
          status: 'fulfilled',
          error: '',
          data: {
            delivery: action.payload.DeliveryMedium,
            destination: action.payload.Destination
          }
        }
      })
      .addCase(_getPasswordCode.rejected, (state, action) => {
        state.getPasswordCode = {
          ...state.getPasswordCode,
          status: 'rejected',
          error: action.error,
          data: ''
        }
      })

      // RESET PASSWORD
      .addCase(_resetPassword.pending, (state, action) => { state.resetPassword = { ...state.resetPassword, status: 'pending', error: '', data: '' } })
      .addCase(_resetPassword.fulfilled, (state, action) => {
        state.resetPassword = {
          ...state.resetPassword,
          status: 'fulfilled',
          error: '',
          data: ''
        }
      })
      .addCase(_resetPassword.rejected, (state, action) => {
        state.resetPassword = {
          ...state.resetPassword,
          status: 'rejected',
          error: action.error,
          data: ''
        }
      })

  }

})

// Action creators are generated for each case reducer function
// "createSlice will automatically generate action creators" - https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
export const { resetPasswordCodeStatus } = accountSlice.actions
export const authenticate = _authenticate
export const getSession = _getSession
export const logout = _logout
export const changePassword = _changePassword
export const getPasswordCode = _getPasswordCode
export const resetPassword = _resetPassword

export default accountSlice.reducer
