// libs
import { configureStore } from '@reduxjs/toolkit'

// app
import accountReducer from './features/Account/accountSlice'
import mileageReducer from './features/Mileage/mileageSlice'
import millSheetsReducer from './features/MillSheets/millSheetsSlice'
//import gasPricesReducer from './features/Mileage/gasPricesSlice'
import toolboxTalkSlice from './features/ToolboxTalks/toolboxTalkSlice'
import schedulesSlice from './features/Schedules/schedulesSlice'
import receivingReducer from './features/Receiving/receivingSlice'

export const store = configureStore({
  reducer: {
    account: accountReducer,
    mileage: mileageReducer,
    millSheets: millSheetsReducer,
    //gasPrices: gasPricesReducer,
    toolboxTalks: toolboxTalkSlice,
    schedules: schedulesSlice,
    receiving: receivingReducer
  },
})
