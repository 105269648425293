// libs
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SignaturePad from 'signature_pad'


// app
import { fetch, saveSignatureImg, updateSession, saveSignatureLog } from './toolboxTalkSlice'
import { TALKS, LOG, cell } from "../../helpers/coda-api"
import { async } from 'regenerator-runtime'

export function ToolboxTalk(props) {

    // https://github.com/szimek/signature_pad
    // YES! React refs allow us to define a "pocket" we can later update and reference
    const signPadRef = useRef(null);

    // get data from store
    const _apiData = useSelector((state) => state.toolboxTalks.api)
    const _currTalk = useSelector((state) => state.toolboxTalks.currWeek)
    const _signatureImg = useSelector((state) => state.toolboxTalks.signature)
    const _signatureLog = useSelector((state) => state.toolboxTalks.signatureLog)
    const _session = useSelector((state) => state.toolboxTalks.session || { errors: [] })

    // how to dispatch actions
    const dispatch = useDispatch()

    // local state
    const [_signEnabled, _updateEnableSign] = useState(false)

    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // NOTE: if data has not been pulled (ie. !== fulfilled), then fetch
        _apiData.status !== 'fulfilled' && dispatch(fetch())

        // NOTE the [] as the second param, this ensures we only run useEffect() once
    }, []);


    // ------
    // HANDLERS
    // ------
    const _handlePDFClick = (evt, href) => {
        evt.preventDefault()
        // need to wrap call to create a sync vs async function (Safari blocks) 
        setTimeout(() => {
            // comment out for testing (do not want opening window every time)
            window.open(href, '_blank')
        })
    }

    const _handleAcknowledgeCheck = evt => {
        // before we assign our new SignaturePad() below, the value of our signPadRef.current is just the DOM element so we can check for className
        if (signPadRef.current.className !== undefined) {
            // need opaque bkgd to save as JPEG
            signPadRef.current = new SignaturePad(document.querySelector("#signature-pad"), {
                backgroundColor: "rgb(255,255,255)"
            })
        }

        if (evt.currentTarget.checked) _updateEnableSign(true)
        else _updateEnableSign(false)
    }

    const _handleSignatureSave = async (evt, talkId) => {
        evt.preventDefault()

        // validation
        let errors = []
        let unchecked
        const empName = document.querySelector('#emp-name')

        // validation
        if (signPadRef.current.isEmpty()) errors.push("signature")
        if (empName.value.trim() === '') errors.push("name")

        if (errors.length > 0) {
            dispatch(updateSession(errors))
            return
        } else {
            dispatch(updateSession([]))
        }

        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL
        const data = signPadRef.current.toDataURL("image/jpeg")

        await window.fetch(data)
            .then(response => {
                // the fetch response includes the image as base64 data in its `url` property
                return dispatch(saveSignatureImg({
                    imgData: response.url,
                    fileName: talkId + "_" + empName.value.trim().replaceAll(/[^a-z]/ig, "").toLowerCase()
                })).then(action => {
                    if ((action.error && error.message === 'Rejected') || (action.type && action.type.slice(-8) === 'rejected')) {
                        // error with Coda update
                    } else {
                        /*
                        // s3 API returns obj. Example:
                        Bucket: "scpb-signatures"
                        ETag: "\"b614c01b30d78df7997196c743318a24\""
                        Key: "safety_106fab_maliabd.jpeg"
                        Location: "https://scpb-signatures.s3.amazonaws.com/safety_106fab_maliabd.jpeg"
                        ServerSideEncryption: "AES256"
                        key: "safety_106fab_maliabd.jpeg"
                        */
                        dispatch(saveSignatureLog({
                            rowData: [
                                {
                                    "column": LOG.talkId,
                                    "value": talkId
                                },
                                {
                                    "column": LOG.employee,
                                    "value": empName.value.trim()
                                },
                                {
                                    "column": LOG.signatureImg,
                                    "value": action.payload.Location
                                }
                            ]
                        }))
                        document.querySelector('#emp-name').value = ""
                        signPadRef.current.clear()
                    }
                })

                // response.blob() is a built in method that converts base64 to binary?
                //return response.blob()
            })
            .catch(e => {
                console.error("error in save", e)
            })
    }

    const _handleSignatureClear = evt => {
        evt.preventDefault()
        signPadRef.current.clear()
    }

    // ------
    // RENDER
    // ------
    return (

        <div>

            {_apiData.status === 'fulfilled' && <img className="toolbox-img" src={cell(_currTalk, TALKS.img)} />}

            <div className='bg-white'>

                {/* CODA DATA | can we re-use this */}
                {_apiData.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading <strong>toolbox talks</strong>...</div>}

                {_apiData.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to load <strong>toolbox talks</strong>.</div>}

                {_apiData.status === 'fulfilled' && <div className="p-2">
                    <div className="gap-2">
                        <div className='mb-2 mt-2'>
                            {/* <h3 className="fw-bold">{cell(_currTalk, TALKS.title)}</h3> */}
                            <div className='text-dark'>Week of {new Date(cell(_currTalk, TALKS.dateScheduled)).toLocaleDateString("en-US", { weekday: 'short', month: 'short', day: 'numeric' })}</div>
                            <div><button className="btn btn-primary mt-2 w-100" onClick={e => { _handlePDFClick(e, cell(_currTalk, TALKS.href)) }}>open PDF <i className='bi-box-arrow-up-right'></i></button></div>
                        </div>
                    </div>
                    <div className='toolbox-content' dangerouslySetInnerHTML={{ __html: cell(_currTalk, TALKS.html) }} />

            {/* SAVING signature to S3 */}
            {_signatureImg.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Saving signature...</div>}
            {_signatureImg.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to save signature.</div>}
            {/*_signatureImg.status === 'fulfilled' && <div className="my-0 w-100 alert alert-success" role="alert"><i className='bi-cloud-check'></i> Signature saved.</div>*/}

            {/* LOGGING entry to CODA */}
            {_signatureLog.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Logging attendance in talk...</div>}
            {_signatureLog.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to save attendance.</div>}
            {_signatureLog.status === 'fulfilled' && <div className="my-0 w-100 alert alert-success" role="alert"><h4><i className='bi-cloud-check'></i> Attendance saved.</h4>Use form below for additional crew.</div>}

                    <div className="mt-3" >

                        <div className='d-flex flex-row gap-2'>
                            <div><input type="checkbox" onChange={_handleAcknowledgeCheck} id="toolbox-acknowledge-check" /></div>
                            <div><p className='toolbox-acknowledge'>I have participated in this week's safety talk covering <em>{cell(_currTalk, TALKS.title)}</em>.</p></div>
                        </div>
                        <p><strong>Please enter your name, and sign below.</strong></p>
                        <div className="mb-2">
                            <input type="text" id="emp-name" className={"form-control" + (_session.errors.includes("name") ? " is-invalid" : "")} placeholder="Enter your name" />
                            <div id="emp-name-validation" className="invalid-feedback">Please enter your name.</div>
                        </div>
                        <div className="toolbox-sign mb-2">
                            <canvas ref={signPadRef} id="signature-pad" className={"signature-pad" + (_session.errors.includes("signature") ? " is-invalid" : "")} style={{ border: "1px solid " + (_session.errors.includes("signature") ? "#C51111" : "#aaa"), width: "100%", height: "100%" }} height="120"></canvas>
                            <div id="signature-pad-validation" className="invalid-feedback">Please sign above.</div>
                        </div>
                        <div className="d-flex flex-row gap-2">
                            <button className='btn btn-outline-primary' onClick={_handleSignatureClear}>Clear</button>
                            <button className='btn btn-primary' onClick={e => _handleSignatureSave(e, cell(_currTalk, TALKS.id))} disabled={_signEnabled ? "" : "disabled"}>Save</button>
                        </div>
                    </div>
                </div>
                }


            </div >
        </div>
    )

}