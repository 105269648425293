// libs
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

// app
import { getPasswordCode, resetPasswordCodeStatus, resetPassword } from "./accountSlice"

export function ForgotPassword(props) {

    // how to dispatch actions
    const dispatch = useDispatch()

    // get data from store
    const _resetPassword = useSelector(state => state.account.resetPassword)
    const _getPasswordCode = useSelector(state => state.account.getPasswordCode)

    // remember all URL params are strings
    let [queryParams, setQueryParams] = useSearchParams()

    // ------
    // UI
    // ------
    // local state
    const [_resetErrors, _updateResetErrors] = useState([])
    const [_haveACode, _updateHaveACode] = useState(false)
    const [_verificationCode, _updateVerificationCode] = useState('')

    const handleCodeRequest = (evt, haveCode) => {
        evt.preventDefault()
        if(haveCode === false) dispatch(resetPasswordCodeStatus({}))
        _updateHaveACode(haveCode)
    }

    // ------
    // FORM
    // ------
    // form handles two scenarios:
    // a] getPasswordCode
    // b] resetPassword 
    const onSubmit = (evt) => {
        evt.preventDefault()

        let requestCode = false
        let errors = []
        const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/gm

        // username is required either way
        const username = document.getElementById("username").value.trim() || ''
        if (username === '') errors.push("You must enter a username")

        const passwordNew = document.getElementById("passwordNew") ? document.getElementById("passwordNew").value.trim() : ''
        const passwordConfirm = document.getElementById("passwordConfirm") ? document.getElementById("passwordConfirm").value.trim() : ''
        const code = document.getElementById("code") ? document.getElementById("code").value.trim() : ''


        if (document.getElementById("passwordNew")) {
            // --- actually submitting new password (resetPassword)

            // validation
            if (passwordNew === '') errors.push("You must enter a new password")
            if (code === '') errors.push("You must enter a verification code")
            if (errors.length === 0 && passwordNew.match(passwordRegex) === null) errors.push('Password does not meet minimum requirements')
            if (passwordNew !== passwordConfirm) errors.push("Passwords must match")

        } else {
            // --- only requesting verification code (getPasswordCode)
            requestCode = true
        }


        if (errors.length == 0) {
            _updateResetErrors([])
            if (requestCode) {
                dispatch(getPasswordCode({ Username: username }))
            } else {
                dispatch(resetPassword({
                    Username: username,
                    verificationCode: code,
                    newPassword: passwordNew
                }))
            }
        } else {
            _updateResetErrors(errors)
        }
    }
    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {

        _updateVerificationCode(queryParams && queryParams.get('code'))
        _updateHaveACode(queryParams && queryParams.get('code') ? true : false)

        // clear all our query params
        setQueryParams({})

        // We need this here, because the code in `/ui/Page.js` only executes on a page title change 
        window.scrollTo(0, 0)

        // NOTE the [] as the second param, this ensures we only run useEffect() once
    }, []);


    // ------
    // RENDER
    // ------
    return (

        <div>

            {_resetPassword.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Submitting <strong>password reset</strong>...</div>}
            {_resetPassword.status === 'rejected' && <div className="mb-0 w-100 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to <strong>reset password</strong>.{_resetPassword.error && <div className="fst-italic mt-2">{_resetPassword.error.message}</div>}</div>}
            {_resetPassword.status === 'fulfilled' && <div className="mb-0 w-100 alert alert-success" role="alert"><i className='bi-emoji-smile'></i> Password reset.</div>}

            {_getPasswordCode.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Submitting <strong>request </strong>...</div>}
            {_getPasswordCode.status === 'rejected' && <div className="mb-0 w-100 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to get<strong>reset code</strong>.{_getPasswordCode.error && <div className="fst-italic mt-2">{_getPasswordCode.error.message}</div>}</div>}
            {_getPasswordCode.status === 'fulfilled' && <div className="mb-0 w-100 alert alert-success" role="alert"><i className='bi-emoji-smile'></i> Verification code sent to <strong>{_getPasswordCode.data.destination}</strong>.</div>}


            {_resetErrors.length > 0 &&
                <div className="mb-0 w-100 alert alert-danger" role="alert">
                    <h4 className="alert-heading"><i className='fs-2 bi-emoji-dizzy'></i> Please fix issues below.</h4>
                    <ul className='mb-0'>{_resetErrors.map((err, idx) => { return <li key={idx}>{err}</li> })}</ul>
                </div>
            }

            <div className="bg-white p-4">

                <div className='p-2'>
                    <Link to='/'> <i className='bi-chevron-left'></i> Back to login</Link>
                </div>

                <form className="p-2" onSubmit={onSubmit}>
                    <div>
                        {(!_haveACode && _getPasswordCode.status !== 'fulfilled') && <p>Enter your username and we will send you a code to reset your password.</p>}

                        <fieldset className="mb-1">
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input type="text" className="form-control" id="username" aria-describedby="username" />
                            </div>
                        </fieldset>
                    </div>

                    {// have a code
                        (_haveACode || _getPasswordCode.status === 'fulfilled') &&
                        <div>
                            <fieldset className="mb-1">
                                <div className="mb-3">
                                    <label htmlFor="code" className="form-label">Verification Code</label>
                                    <input type="text" className="form-control" id="code" aria-describedby="code" defaultValue={_verificationCode} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="passwordNew" className="form-label">New Password</label>
                                    <input type="password" className="form-control" id="passwordNew" aria-describedby="passwordNew" />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="passwordConfirm" className="form-label">Confirm New Password</label>
                                    <input type="password" className="form-control" id="passwordConfirm" aria-describedby="passwordConfirm" />
                                </div>

                            </fieldset>
                        </div>}
                    <div>
                        <button type="submit" className="btn btn-primary w-100 mb-3">{(_haveACode || _getPasswordCode.status === 'fulfilled') ? 'Reset Password' : 'Request Code'}</button>
                        {(!_haveACode && _getPasswordCode.status !== 'fulfilled') && <a href="#" onClick={evt => handleCodeRequest(evt, true)}>I already have a code.</a>}
                        {(_haveACode || _getPasswordCode.status === 'fulfilled') && <a href="#" onClick={evt => handleCodeRequest(evt, false)}>I need a new code.</a>}
                    </div>
                </form>
            </div >

        </div>
    )
}

