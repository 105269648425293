// libs
import React from "react"
import { useDispatch } from 'react-redux'
import { Link } from "react-router-dom";

// app


export function Header(props) {

    // how to dispatch actions
    const dispatch = useDispatch()

    const requireAuth = props.user.noAuth ? false : true

    return (
        <header className="py-1 px-3 container">
            <div className="d-flex justify-content-between align-items-center" style={{ gridTemplateColumns: '1fr 2fr' }}>
                <Link className="align-items-center text-decoration-none" to="/">
                    <svg className="me-2" role="img" viewBox="0 0 445 104" width="200px" focusable="false" aria-hidden="true">
                        <use href="svg/SCPB-horiz--UPDATED.svg#main"></use>
                    </svg>
                </Link>
                {/*props.user.name !== '' && <h3 className="text-dark">{props.title}</h3>*/}
                {(requireAuth && props.user.name !== '') && <div className="flex-grow-1 text-end">
                    Hi, <strong>{props.user.name.split(" ")[0]}</strong>!<br />
                    <Link
                        className=''
                        to={`/account`}
                    >
                        Account 
                    </Link>
                </div>}
            </div>
        </header>
    )
}

