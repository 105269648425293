// local vs AWS
export const URL = 'https://y047k9f2mk.execute-api.us-east-1.amazonaws.com/api/doc'
//export const URL = 'http://localhost:3000/coda/api/doc'


export const MATERIAL = {
    name: 'c-qDxmhYG1VX',
    summary: 'c-ZLHP3wh4PS',
    job: 'c-Z-5oLVP4cn',
    by: 'c-gTADP_L1I2',
    date: 'c-BWyM0Ej8VN',
    vendor: 'c-g7_Lt6XYxU',
    type: 'c-YRUs1mRUkr',
    vendorRef: 'c-vZvbs3Mgei',
    details: 'c-Mde5mylay5',
    notes: 'c-KDBdM8wlAk',
    destination: 'c-zFBggiL9Tb',
    expected: 'c-Yu0O6owGUc',
    uiDetails: 'c-6BmsLrGYeG',
    acknowledgement: 'c-NAHvXJ5tNs',
    recDate: 'c-XhweKPGyEa',
    recBy: 'c-GvQZKRlWjs',
    recNotes: 'c-NOGAbprQIU',
    projMgr: 'c-EQTtXK0VXc',
    fileUrl: 'c-XJLjpIyoHR',
    recProblem: 'c-K6tKi5VlZy',
    recMissing: 'c-0_HFkcGJ6n'
}

export const MATERIAL_PARTIAL = {
    // on a related table, Coda will recognize the related entry by name!
    receivingLink: 'c-mxkhj5SDMo',
    recDate: 'c--AoOBhndGH',
    recBy: 'c-Wf8N9GKjbo',
    recNotes: 'c-QTh9IZWP8Q',
    recMissing: 'c-Y_TaDJPIqJ',
    origMaterials: 'c-3UdKLjQtbZ'
}

export const RECEIVING = {
    name: 'c-qDxmhYG1VX',
    summary: 'c-ZLHP3wh4PS',
    job: 'c-Z-5oLVP4cn',
    by: 'c-gTADP_L1I2',
    date: 'c-BWyM0Ej8VN',
    vendor: 'c-g7_Lt6XYxU',
    type: 'c-YRUs1mRUkr',
    vendorRef: 'c-vZvbs3Mgei',
    details: 'c-Mde5mylay5',
    notes: 'c-KDBdM8wlAk',
    destination: 'c-zFBggiL9Tb',
    expected: 'c-Yu0O6owGUc',
    uiDetails: 'c-6BmsLrGYeG',
    acknowledgement: 'c-NAHvXJ5tNs',
    received: 'c-rNtFCSuR9u', // this is calculated by recDate
    recDate: 'c-XhweKPGyEa',
    recBy: 'c-GvQZKRlWjs',
    recNotes: 'c-NOGAbprQIU',
    projMgr: 'c-EQTtXK0VXc',
    fileUrl: 'c-XJLjpIyoHR',
    recProblem: 'c-K6tKi5VlZy',
    recMissing: 'c-0_HFkcGJ6n',
    timberCert: 'c-WHj0eOolMg'
}

export const RECEIVING_PARTIAL = {
    // on a related table, Coda will recognize the related entry by name!
    receivingLink: 'c-mxkhj5SDMo',
    recDate: 'c--AoOBhndGH',
    recBy: 'c-Wf8N9GKjbo',
    recNotes: 'c-QTh9IZWP8Q',
    recMissing: 'c-Y_TaDJPIqJ',
    origMaterials: 'c-3UdKLjQtbZ'
}





export const TALKS = {
    id: 'c-umdngHIVu1',
    title: 'c-lKqVHkBtOy',
    img: 'c-rMY4mKlXgt',
    href: 'c-PmQjmjU4Z8',
    dateGiven: 'c-DcSR26q6Iz',
    dateScheduled: 'c-eiK7fSzS8i',
    html: 'c-UH0S43JVbl'
}

export const SCHED = {
    employee: 'c-JD9mdr9lBW',
    weekOf: 'c-T-H1briIfm',
    monday: 'c-PPtxnm8W0c',
    tuesday: 'c-_8i6Nn2dsL',
    wednesday: 'c-N8gtKXTF3x',
    thursday: 'c-njoOKEdkYk',
    friday: 'c-S2gdhzs1Pn',
    name: 'c-n5M8fzSBlS'
}

export const SCHED_LOCATION = {
    schedule: 'c-gDBv2yWZ9u',
    groupBy: 'c-TdFHCYE01R',
    address: 'c-YOz2mVsTvx',
    name: 'c-48EhRfVTL1',
    googleMap: 'c-z62QCCx0y9'
}



export const LOG = {
    talkId: 'c-nG8DlCc-_R',
    employee: 'c-lAxurbmsdV',
    signatureImg: 'c-v9T4i47lgR'
}

/**
 * sheets in smartsheet = tables in coda; need to create a more complex URL to access
 * each view of a table is a table as well (view becomes a child)
 * since I name my pages with same name as tables, need to be mindful of "type" in API response
 * 
 * can get API data just hitting following URL in browser (NOTE: doc id = XshSxekT0s)
 * https://y047k9f2mk.execute-api.us-east-1.amazonaws.com/api/doc/XshSxekT0s/tables
 * 
 */
export const DOC = {
    TTS: {
        id: 'XshSxekT0s',
        tables: {
            // note: new material order table
            MATERIAL: 'grid-LlR2bC-HWD',
            MATERIAL_PARTIAL: 'grid-qhqXhIXscl',
            RECEIVING: 'grid-LlR2bC-HWD',
            RECEIVING_PARTIAL: 'grid-qhqXhIXscl'
        }
    },
    SAFETY: {
        id: 'bUOX9EgqFz',
        tables: {
            TALKS: 'grid-xLud7rCt_m',
            LOG: 'grid-dy8Hb7KMF2'
        }
    },
    SCHED: {
        id: 'sVrX5niZJS',
        tables: {
            SCHEDULES: 'grid-Zfe0MX8aBb',
            LOCATIONS: 'grid-8fZrghgTCk'
        }
    }
}


export const cell = (rowData = {}, columnId) => {
    if (!rowData.values) return "(cell) invalid row data, missing values"
    if (!rowData.values[columnId] && rowData.values[columnId] !== 0 && (rowData.values[columnId] && rowData.values[columnId].trim() !== "")) return "(cell) invalid column id: " + columnId
    return rowData.values[columnId]
}

// factory function which allows us to "instantiate" a coda row and access its cells based on columnId
export const createCodaRow = (rowData = {}) => {
    return function (columnId) {
        if (!rowData.values) return "(cell) invalid row data, missing values"
        if (!rowData.values[columnId] && rowData.values[columnId] !== 0 && (rowData.values[columnId] && rowData.values[columnId].trim() !== "")) return "(cell) invalid column id: " + columnId
        return rowData.values[columnId]
    }

}