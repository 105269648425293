// libs
import React, { useEffect, lazy, Suspense } from "react"
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
// needed for bootstrap tab functionality
import { Tab } from 'bootstrap'

// app
import "../sass/style.scss"
import { usePageTracking } from "./helpers/google-analytics"

// routes
import Page from "./ui/Page"
import Home from "./ui/Home"


/*
import { Mileage } from "./features/Mileage/Mileage"
import { MileageEntry } from "./features/Mileage/MileageEntry"
import { MileageSummaries } from "./features/Mileage/MileageSummaries"
*/

// `React.lazy()` expects a default export, not named - we can work around by renaming on dynamic import
const Mileage = lazy(() => import("./features/Mileage/Mileage").then(module => { return { default: module.Mileage } }))
const MileageEntry = lazy(() => import("./features/Mileage/MileageEntry").then(module => { return { default: module.MileageEntry } }))
const MileageSummaries = lazy(() => import("./features/Mileage/MileageSummaries").then(module => { return { default: module.MileageSummaries } }))

//import { MasterFormat } from "./features/MasterFormat/MasterFormat"

import { MillSheets } from "./features/MillSheets/MillSheets"
import { MillSheet } from "./features/MillSheets/MillSheet"
import { MillSheetsList } from "./features/MillSheets/MillSheetsList"

import { ToolboxTalk } from "./features/ToolboxTalks/ToolboxTalk";

import { Schedule } from "./features/Schedules/Schedule";

// most common used, do not lazy load
import { Receivings } from "./features/Receiving/Receivings"
import { Receiving } from "./features/Receiving/Receiving"
import { ReceivingList } from "./features/Receiving/ReceivingList"


import { getSession } from "./features/Account/accountSlice"
import { Account } from "./features/Account/Account"
import { ForgotPassword } from "./features/Account/ForgotPassword";

// react component vs functional component
// -- v16.8 of react provided a way to create stateful functional components
// -- feeling is that functional components are the future
const App = () => {

    // how to dispatch actions
    const dispatch = useDispatch()
    // get data from store
    const _account = useSelector((state) => state.account)

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        dispatch(getSession())
    }, [])

    usePageTracking()

    return (
        <Routes>
            <Route path="/" element={
                <Page
                    title="Home"
                    user={_account.user}
                >
                    <Home user={_account.user} />
                </Page>} />

            {/* ---------------------------------
            RECEIVING (Coda)
            --------------------------------- */}
            <Route path="/receiving" element={
                <Page
                    title="Receiving"
                    user={_account.user}
                >
                    <Receivings user={_account.user} />
                </Page>}>

                {/* the 2 routes below are child routes and leverage the <Outlet /> feature of react-router */}
                <Route index element={<ReceivingList />} />
                <Route path=":receivingId" element={<Receiving />} />
            </Route>


            {/* ---------------------------------
            MILEAGE
            --------------------------------- */}
            <Route path="/mileage" element={
                <Page
                    title="Mileage"
                    user={_account.user}
                >
                    <Suspense><Mileage user={_account.user} /></Suspense>
                </Page>}>

                {/* the 2 routes below are child routes and leverage the <Outlet /> feature of react-router */}
                {/* @todo:  WHY do we need to pass user here, but not in other sub components? */}
                <Route index element={<Suspense><MileageEntry user={_account.user} /></Suspense>} />
                <Route path="/mileage/summaries" element={<Suspense><MileageSummaries /></Suspense>} />
            </Route>

            {/* ---------------------------------
            MILL SHEETS
            --------------------------------- */}
            <Route path="/mill-sheets" element={
                <Page
                    title="Mill Sheets"
                    user={_account.user}
                >
                    <MillSheets user={_account.user} />
                </Page>}>

                {/* the 2 routes below are child routes and leverage the <Outlet /> feature of react-router */}
                <Route index element={<MillSheetsList />} />
                <Route path=":millSheetId" element={<MillSheet />} />
            </Route>

            {/* ---------------------------------
            TOOLBOX TALKS
            --------------------------------- */}
            <Route path="/toolbox-talks" element={
                <Page
                    title="Toolbox Talks"
                    user={{ noAuth: true }}
                >
                    <ToolboxTalk />
                </Page>}>
            </Route>

            {/* ---------------------------------
            SCHEDULES
            --------------------------------- */}
            <Route path="/schedule" element={
                <Page
                    title="Schedule"
                    user={{ noAuth: true }}
                >
                    <Schedule />
                </Page>}>
            </Route>

            {/* ---------------------------------
            MASTERFORMAT
            --------------------------------- 
            <Route path="/masterformat" element={
                <Page
                    title="MasterFormat"
                    user={_account.user}
                >
                    <MasterFormat />
                </Page>}>
            </Route>*/}

            {/* ---------------------------------
            ACCOUNT
            --------------------------------- */}
            <Route path="/account" element={
                <Page
                    title="My Account"
                    user={_account.user}
                >
                    <Account />
                </Page>}>
            </Route>

            {/* ---------------------------------
            FORGOT PASSWORD
            --------------------------------- */}
            <Route path="/forgot-password" element={
                <Page
                    title="Forgot Password"
                    user={{ noAuth: true }}
                >
                    <ForgotPassword />
                </Page>}>
            </Route>

            {/* ---------------------------------
            CATCH-ALL
            --------------------------------- */}
            <Route path="*" element={
                <Page
                    title="Home*"
                    user={_account.user}
                >
                    <div>
                        <Home user={_account.user} />
                        <pre className="text-muted mt-5">Catch-all route.<br />All features may not be available.</pre>
                    </div>
                </Page>} />
        </Routes>
    )
}

export default App