// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client(endpoint, { body, ...customConfig } = {}) {

    const headers = { 'Content-Type': 'application/json' }

    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    }

    if (body) {
        config.body = JSON.stringify(body)
    }

    return window
        .fetch(endpoint, config)
        .then(async response => {
            if (response.ok) {
                //console.log("ok")
                return await response.json()
            } else {
                //console.log("bad")
                const errorMessage = await response.text()
                return Promise.reject(new Error(errorMessage))
            }
        })
        .catch(err => {
            //console.log("bad")
            // window.fetch() doesnt provide any real meaningful error information
            let _err
            try {
                // need to look at Coda API error response, way I am nesting an error in the body of the response, etc.
                const obj =  JSON.parse(err.message)
                _err = new Error(obj.message || "/api/client → unexpected err response")
            } catch (err){
                _err = err || new Error("/api/client → error")
            }
            const errorMessage = _err instanceof Error ? _err.message === "Failed to fetch" ? "/api/client → fetch() error" : _err.message : "/api/client → .catch() error"
            return Promise.reject(new Error(errorMessage))
        })
}

client.get = function (endpoint, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body })
}

// [ TJB ] added PUT method handler
client.put = function (endpoint, body, customConfig = {}) {
    //console.log("./api/client → ", endpoint)
    return client(endpoint, { body, ...customConfig, method: 'PUT' })
}
