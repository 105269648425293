// https://github.com/thisismydesign/cra-typescript-starter/blob/c5aeb50a9cda064486453c3095b34a76b1080aaa/src/utils/tracking/tracking.ts
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import localLog from "./local-log"

export const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    localLog("Tracking page view", location.pathname + location.search)

    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    })
  }, [location])
}

export const captureUserId = (userId) => {
  window.gtag("config", 'G-3DXV53JFXS', {
    'user_id': userId
  });
}