const _rootItem = 'ScpbPortal'

// used to validate set attempts 
const _schema = {
    username: '',
    rememberUsername: '',
    haveReadAnnounce: false,
    recentJobs: []
}

const _get = () => {
    // get our "root" item and convert to object for manipulation
    try {
        const i = localStorage.getItem(_rootItem)
        if (i) return JSON.parse(i)
        else {
            localStorage.setItem(_rootItem, JSON.stringify(_schema))
            return _schema
        }
    } catch (e) {
        throw e
    }
}

// API ----------------
export const setLocal = (item, val) => {
    if (typeof _schema[item] === undefined) {
        console.error('invalid item for localStorage: ' + item)
        return new Error('invalid item for localStorage')
    }

    const i = _get()
    if (i) {
        i[item] = val
        localStorage.setItem(_rootItem, JSON.stringify(i))
    } else {
        return new Error(`unable to set '${item}' on localStorage`)
    }
}


export const getLocal = (item) => {
    try {
        const i = _get()
        return i && i[item] || new Error(`'${item}' not found`)
    } catch (e) {
        return e
    }
}


export const removeLocal = () => {
    localStorage.removeItem(_rootItem)
}