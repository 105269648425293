// libs
import React, { useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom"

// app
import { authenticate } from "./accountSlice"
import { getLocal, setLocal } from "../../helpers/localstorage"

const Login = () => {

    // how to dispatch actions
    const dispatch = useDispatch()
    // get data from store
    const _account = useSelector((state) => state.account)

    // message is equal to any error messaging returned by localstorage module
    const _username = getLocal("username").message ? '' : getLocal("username")
    const _rememberUsername = getLocal("rememberUsername").message ? false : getLocal("rememberUsername")

    // useState isolates state to THIS component and is *separate* from app state (redux store)
    const [email, setEmail] = useState(_username)
    const [password, setPassword] = useState()

    const onSubmit = (evt) => {
        evt.preventDefault()

        if (evt.target.rememberUsername.checked) {
            setLocal("rememberUsername", true)
            setLocal("username", email)
        } else {
            setLocal("rememberUsername", false)
            setLocal("username", '')
        }

        // cognito wants upercase params
        dispatch(authenticate({
            Username: email,
            Password: password
        }))
    }

    const togglePasswordShow = evt => {
        evt.preventDefault()
        const field = document.getElementById("password")
        if (field.type === "password") {
            field.type = "text"
            evt.target.innerHTML = "hide"
        } else {
            field.type = "password"
            evt.target.innerHTML = "show"
        }
    }

    return (
        <div>

            {/* LOGIN STATUS | can we re-use this */}
            {_account.auth.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Logging in...</div>}
            {_account.auth.status === 'fulfilled' && <div className="my-0 w-100 alert alert-success" role="alert"><i className='bi-cloud-check'></i> Login success!</div>}
            {_account.auth.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to login.<br />- {_account.auth.error}</div>}

            <div className="bg-white p-4">

                <Link className="btn btn-lg btn-primary btn-block w-100 mb-5" aria-current="page" to="/toolbox-talks"><i className='bi-tools fs-1'></i><br />Toolbox Talk</Link>

                <form className="p-2" onSubmit={onSubmit}>
                    <fieldset className="mb-3">
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Username</label>
                            <input
                                value={email}
                                onChange={evt => setEmail(evt.target.value)}
                                type="text"
                                className="form-control"
                                id="email"
                                aria-describedby="email" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password <a href="#" onClick={togglePasswordShow}>show</a></label>
                            <input
                                value={password}
                                onChange={evt => setPassword(evt.target.value)}
                                type="password"
                                className="form-control"
                                id="password"
                                aria-describedby="password" />
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={true} defaultChecked={_rememberUsername} id="rememberUsername" />
                            <label className="form-check-label" htmlFor="rememberUsername">Remember username</label>
                        </div>
                    </fieldset>
                    <div>
                        <button type="submit" className="btn btn-primary w-100 mb-3">Login</button>
                        <Link to='/forgot-password'>Forgot password?</Link>
                    </div>
                </form>
            </div >
        </div>
    )
}

export default Login