// libs
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { add, sub } from 'date-fns'

// app
import { fetch, RANGE, filterScheduleData, fetchLocations, filterLocations } from './schedulesSlice'
import { SCHED, cell, SCHED_LOCATION } from "../../helpers/coda-api"
import { async } from 'regenerator-runtime'

export function Schedule(props) {

    // get data from store
    const _apiData = useSelector((state) => state.schedules.api)
    const _selectedWeek = useSelector((state) => state.schedules.filtered.entries)
    const _selectedUniqueJobs = useSelector((state) => state.schedules.filtered.unique)
    const _locationData = useSelector((state) => state.schedules.locations)
    const _selectedLocations = useSelector((state) => state.schedules.locations.filtered) || []

    // how to dispatch actions
    const dispatch = useDispatch()

    // local state
    const [_dateRange, _updateDateRange] = useState(RANGE.currWeek)
    const [_sortByJob, _updateSortByJob] = useState(false)

    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // NOTE: if data has not been pulled (ie. !== fulfilled), then fetch
        _apiData.status !== 'fulfilled' && dispatch(fetch())
        _locationData.status !== 'fulfilled' && dispatch(fetchLocations())
        // NOTE the [] as the second param, this ensures we only run useEffect() once
    }, []);


    // ------
    // HANDLERS
    // ------

    const handlePrevWeek = (evt) => {
        evt.preventDefault()
        _updateDateRange(RANGE.prevWeek)
        dispatch(filterLocations({ dateRange: RANGE.prevWeek }))
        dispatch(filterScheduleData({ dateRange: RANGE.prevWeek, sort: _sortByJob ? "job" : "name" }))
    }

    const handleCurrWeek = (evt) => {
        evt.preventDefault()
        _updateDateRange(RANGE.currWeek)
        dispatch(filterLocations({ dateRange: RANGE.currWeek }))
        dispatch(filterScheduleData({ dateRange: RANGE.currWeek, sort: _sortByJob ? "job" : "name" }))
    }

    const handleNextWeek = (evt) => {
        evt.preventDefault()
        _updateDateRange(RANGE.nextWeek)
        dispatch(filterLocations({ dateRange: RANGE.nextWeek }))
        dispatch(filterScheduleData({ dateRange: RANGE.nextWeek, sort: _sortByJob ? "job" : "name" }))
    }

    const handleSortChange = (evt) => {
        _updateSortByJob(evt.target.checked)
        // NOTE: we need to use a checkbox check to determine sortBy value here (because _updateSortByJob() is not instant)
        dispatch(filterScheduleData({ dateRange: _dateRange, sort: evt.target.checked ? "job" : "name" }))
    }


    // ------
    // RENDER
    // ------

    // days which start in the shop require special treatment
    // looking for schedule item with shop **AND** a job
    const _shopStartReg = /(» SHOP.*[0-9]+|[0-9]+.*» SHOP)/gi

    const _getDayCSS = (row, key) => {
        const txt = cell(row, key)
        // note: we lookup the first NUMBERED job
        const idx = _selectedUniqueJobs.indexOf(txt.replace(/(,?» SHOP,?|,?» K2,?)/, ""))
        if (idx >= 0) return `day-install-${idx + 1}`
        if (txt.match(/off/gi)) return "day-off"
        if (txt.match(_shopStartReg)) return "day-install"
        if (txt.match(/[0-9]*:/gi)) return "day-install"
        if (txt.match(/shop/gi)) return "day-shop"
        if (txt.match(/k2/gi)) return "day-k2"
        else return ""
    }

    const _formatDay = (row, key) => {
        const txt = cell(row, key)
        if (txt.match(_shopStartReg)) {
            return txt.replace(/,?» SHOP,?/, "") + '<div style="background-color: #f3cfcf; padding: 5px; color: #721c24;" class="fw-normal"><em>start at shop</em></div>'
        } else return txt
    }

    const _googleMap = address => {
        return `<a href="https://www.google.com/maps/place/${address.trim().replace(/\./, "").replace(/ /ig, "+")}" target="_blank">${address}</a>`
    }


    const _selectedWeekDate = _selectedWeek.length === 0 ? false : new Date(cell(_selectedWeek[0], SCHED.weekOf))

    const _dateLabels = {
        week: _selectedWeekDate ? _selectedWeekDate.toLocaleDateString("en-US", { weekday: 'short', month: 'short', day: 'numeric' }) : "No schedule yet.",
        mon: _selectedWeekDate ? _selectedWeekDate.toLocaleDateString("en-US", { month: 'numeric', day: 'numeric' }) : "",
        tue: _selectedWeekDate ? add(_selectedWeekDate, { days: 1 }).toLocaleDateString("en-US", { month: 'numeric', day: 'numeric' }) : "",
        wed: _selectedWeekDate ? add(_selectedWeekDate, { days: 2 }).toLocaleDateString("en-US", { month: 'numeric', day: 'numeric' }) : "",
        thu: _selectedWeekDate ? add(_selectedWeekDate, { days: 3 }).toLocaleDateString("en-US", { month: 'numeric', day: 'numeric' }) : "",
        fri: _selectedWeekDate ? add(_selectedWeekDate, { days: 4 }).toLocaleDateString("en-US", { month: 'numeric', day: 'numeric' }) : "",
    }


    return (

        <div>

            <div className='bg-white'>

                {/* CODA DATA | can we re-use this */}
                {_apiData.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading <strong>schedule</strong>...</div>}

                {_apiData.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to load <strong>schedule</strong>.</div>}

                {_apiData.status === 'fulfilled' && <div className="p-2">
                    <div className="gap-2">
                        <div className='mb-2 mt-2'>
                            <h3 className='pb-2 mb-2' style={{ borderBottom: "1px solid #c7c8c9" }}><span className='text-muted fw-normal'>Week of</span> <strong>{_dateLabels.week}</strong></h3>

                            {_locationData.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading <strong>locations</strong>...</div>}

                            {_locationData.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to load <strong>locations</strong>.</div>}

                            {(_locationData.status === 'fulfilled' && _selectedLocations.length > 0) ? <div>
                                {_selectedLocations.map((location, idx) => {
                                    return <div key={idx} className="pb-2">
                                        <strong>{cell(location, SCHED_LOCATION.schedule)}</strong><br />
                                        <a href={"https://www.google.com/maps/place/" + cell(location, SCHED_LOCATION.address).trim().replace(/\./, "").replace(/ /ig, "+")} target="_blank">{cell(location, SCHED_LOCATION.address)}</a>
                                    </div>
                                })}
                                <hr />
                            </div> : ""}

                            <div className='pb-2'><input className="form-check-input" type="checkbox" checked={_sortByJob} id="sortByJob" onChange={handleSortChange} /> <label className="form-check-label" htmlFor="sortByJob">Sort by job</label></div>
                            <div className='d-flex gap-2 justify-content-between align-items-center'>
                                <button
                                    className={'btn ' + (_dateRange === RANGE.prevWeek ? 'btn-dark' : 'btn-light')}
                                    aria-label="sort by vendor"
                                    onClick={handlePrevWeek}
                                >
                                    prev week
                                </button>
                                <button
                                    className={'btn ' + (_dateRange === RANGE.currWeek ? 'btn-dark' : 'btn-light')}
                                    aria-label="sort by date"
                                    onClick={handleCurrWeek}
                                >
                                    curr week
                                </button>
                                <button
                                    className={'btn ' + (_dateRange === RANGE.nextWeek ? 'btn-dark' : 'btn-light')}
                                    aria-label="sort by date"
                                    onClick={handleNextWeek}
                                >
                                    next week
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3" >

                        <div className="table-responsive">
                            <table className="table table-bordered table-schedule">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>MON<br />{_dateLabels.mon}</th>
                                        <th>TUE<br />{_dateLabels.tue}</th>
                                        <th>WED<br />{_dateLabels.wed}</th>
                                        <th>THU<br />{_dateLabels.thu}</th>
                                        <th>FRI<br />{_dateLabels.fri}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {_selectedWeek.map((emp, idx) => {
                                        return <tr key={idx}>
                                            <td className="fw-bold">{cell(emp, SCHED.name)}</td>
                                            <td className={_getDayCSS(emp, SCHED.monday)}><div className="badge">MON</div><div dangerouslySetInnerHTML={{ __html: _formatDay(emp, SCHED.monday) }}></div></td>
                                            <td className={_getDayCSS(emp, SCHED.tuesday)}><div className="badge">TUE</div><div dangerouslySetInnerHTML={{ __html: _formatDay(emp, SCHED.tuesday) }}></div></td>
                                            <td className={_getDayCSS(emp, SCHED.wednesday)}><div className="badge">WED</div><div dangerouslySetInnerHTML={{ __html: _formatDay(emp, SCHED.wednesday) }}></div></td>
                                            <td className={_getDayCSS(emp, SCHED.thursday)}><div className="badge">THU</div><div dangerouslySetInnerHTML={{ __html: _formatDay(emp, SCHED.thursday) }}></div></td>
                                            <td className={_getDayCSS(emp, SCHED.friday)}><div className="badge">FRI</div><div dangerouslySetInnerHTML={{ __html: _formatDay(emp, SCHED.friday) }}></div></td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }


            </div >
        </div>
    )

}