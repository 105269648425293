// local vs AWS
//export const URL = 'http://localhost:3000/sheets'
export const URL = 'https://y5nx4anyfg.execute-api.us-east-1.amazonaws.com/api/sheets'

export const NOTICE = {
    vendor: 0,
    jobId: 1,
    projMgr: 2,
    docId: 3,
    docCreated: 4,
    // status is health
    docStatus: 5,
    // stage is workflow
    docStage: 6,
    formJob: 7, 
    formProjMgr: 8,
    vendorOrderId: 9,
    orderType: 10,
    orderDate: 11,
    orderBy: 12,
    orderExpected: 13,
    orderDestination: 14,
    orderSummary: 15,
    hasMaterialsFile: 16,
    orderMaterials: 17,
    orderInstructions: 18,
    receiveDate: 19,
    receiveBy: 20,
    receiveComments: 21,
    isBackordered: 22
}

export const TRIP = {
    user: 0,
    jobId: 1,
    formJob: 2,
    tripCreated: 3,
    tripDate: 4,
    startLat: 5,
    startLong: 6,
    startAddress: 7,
    endLat: 8,
    endLong: 9,
    endAddress: 10,
    tripTotal: 11,
    startIsHome: 12,
    endIsHome: 13
}

export const MILL = {
    jobId: 0,
    projMgr: 1,
    docId: 2,
    docCreated: 3,
    docStage: 4,
    formJob: 5,
    formProjMgr: 6,
    orderBy: 7,
    workOrdered: 8,
    workSummary: 9,
    hasDescriptionFile: 10,
    workDescription: 11,
    workDelivery: 12,
    assignee: 13,
    completeDate: 14,
    completeBy: 15,
    completeNotes: 16,
    completeTarget: 17
}


export const SHEET = {
    receiving: 6845696608364420,
    mileage: 1581970179286916,
    millSheets: 5561332540958596
}

export const cell = (rowData = {}, columnName, useRawValue = false) => {
    if (!rowData.cells) return "(cell) invalid row data"
    if (!rowData.cells[columnName] && rowData.cells[columnName] !== 0) return "(cell) invalid column name: " + columnName
    // not all fields even have a displayValue
    return (useRawValue || !rowData.cells[columnName].displayValue) ? rowData.cells[columnName].value : rowData.cells[columnName].displayValue
}

export const colId = (rowData = {}, columnName) => {
    if (!rowData.cells) return "(col id) invalid row data"
    if (!rowData.cells[columnName] && rowData.cells[columnName] !== 0) return "(col id) invalid column name: " + columnName
    return rowData.cells[columnName].columnId
}


export const sheetColId = (rowData = {}, columnName) => {
    if (!rowData[columnName] && rowData[columnName] !== 0) return "(sheet col id) invalid column name: " + columnName
    return rowData[columnName]
}








