// libschangePassword
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// app
import { logout, changePassword } from "./accountSlice"

export function Account(props) {

    // how to dispatch actions
    const dispatch = useDispatch()

    // get data from store
    const _user = useSelector((state) => state.account.user)
    const _changePassword = useSelector((state) => state.account.changePassword)
    const _session = useSelector((state) => state.account.session)


    const _logout = evt => {
        evt.preventDefault()
        dispatch(logout())
    }

    // ------
    // UI
    // ------
    // local state
    const [_passwordErrors, _updatePasswordErrors] = useState([])
    const [_showPasswordForm, _updateShowPasswordForm] = useState(false)

    // ------
    // FORM
    // ------
    const handlePasswordChange = evt => {

        evt.preventDefault()
        _updatePasswordErrors([])

        let errors = []
        const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/gm

        const passwordCurrent = document.getElementById("passwordCurrent").value.trim() || ''
        const passwordNew = document.getElementById("passwordNew").value.trim() || ''
        const passwordConfirm = document.getElementById("passwordConfirm").value.trim() || ''

        // validation
        if (passwordCurrent === '') errors.push("You must enter your current password")
        if (passwordNew === '') errors.push("You must enter a new password")
        if (errors.length === 0 && passwordNew.match(passwordRegex) === null) errors.push('Password does not meet minimum requirements')
        if (passwordNew !== passwordConfirm) errors.push("Passwords must match")

        if (errors.length == 0) {
            _updatePasswordErrors([])
            dispatch(changePassword({
                oldPassword: passwordCurrent,
                newPassword: passwordNew
            })).then(action => {
                if ((action.type && action.type.slice(-8) === 'rejected')) {
                    // error from Cognito, thunk will create a "type" from the named action with a "rejected" suffix 
                    // ex: { type: "account/changePassword/rejected" }
                    window.scrollTo(0, 0)
                } else {
                    // Cognito succeeded, NOW we can do cleanup
                    document.getElementById("passwordCurrent").value = ''
                    document.getElementById("passwordNew").value = ''
                    document.getElementById("passwordConfirm").value = ''
                    //
                    window.scrollTo(0, 0)
                    _updateShowPasswordForm(false)
                }
            })

        } else {
            window.scrollTo(0, 0)
            _updatePasswordErrors(errors)
        }
    }

    const onChangeShowPassword = (evt, val) => {
        evt.preventDefault()
        if (val === undefined) val = true
        _updateShowPasswordForm(val)
    }

    const togglePasswordShow = evt => {
        evt.preventDefault()
        const field = document.getElementById("passwordCurrent")
        if (field.type === "password") {
            field.type = "text"
            document.getElementById("passwordNew").type = "text"
            document.getElementById("passwordConfirm").type = "text"
            evt.target.innerHTML = "hide passwords"
        } else {
            field.type = "password"
            document.getElementById("passwordNew").type = "password"
            document.getElementById("passwordConfirm").type = "password"
            evt.target.innerHTML = "show passwords"
        }
    }
 
    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // NOTE the [] as the second param, this ensures we only run useEffect() once
    }, []);




    // ------
    // RENDER
    // ------


    return (
        <div>

            {_changePassword.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Updating <strong>user account</strong>...</div>}
            {_changePassword.status === 'rejected' && <div className="mb-0 w-100 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to update <strong>user account</strong>.{_changePassword.error && <div className="fst-italic mt-2">{_changePassword.error.message}</div>}</div>}
            {_changePassword.status === 'fulfilled' && <div className="mb-0 w-100 alert alert-success" role="alert"><i className='bi-emoji-smile'></i> <strong>User account</strong> updated.</div>}

            {_passwordErrors.length > 0 &&
                <div className="mb-0 w-100 alert alert-danger" role="alert">
                    <h4 className="alert-heading"><i className='fs-2 bi-emoji-dizzy'></i> Please fix issues below.</h4>
                    <ul className='mb-0'>{_passwordErrors.map((err, idx) => { return <li key={idx}>{err}</li> })}</ul>
                </div>
            }

            <div className='p-4 bg-white'>

                <table className="mt-2 table table-sm table-borderless">
                    <tbody>
                        <tr className="">
                            <td className="label-data w-25">USERNAME</td>
                            <td className='fw-bold'>{_user.userName}</td>
                        </tr>
                        <tr className="border-top">
                            <td className="label-data w-25">NAME</td>
                            <td className='fw-bold'>{_user.name}</td>
                        </tr>
                        <tr className="border-top">
                            <td className="label-data w-25">eMAIL</td>
                            <td className='fw-bold'>{_user.email}</td>
                        </tr>
                    </tbody>
                </table>

                <div className='mb-3'><button disabled={_showPasswordForm} className="btn btn-primary w-100" onClick={onChangeShowPassword}>change password</button></div>
                
                <div><button className="btn btn-outline-primary w-100" onClick={_logout}>logout</button></div>

                {_showPasswordForm && <div>
                    <hr />
                    <div className='d-flex justify-content-between'>
                    <p className='fw-bold'>Change your password below.</p>
                    <button className="btn" onClick={e => {onChangeShowPassword(e, false)}}><i className="bi bi-x-lg"></i></button>
                    </div>
                    <ul>
                        <li>at least 8 characters long</li>
                        <li>at least 1 number</li>
                        <li>at least 1 capital letter</li>
                        <li>at least 1 lowercase letter</li>
                        <li>at least 1 special character</li>
                    </ul>
                    <form onSubmit={handlePasswordChange} className="p-2">
                        <div><a href="#" onClick={togglePasswordShow}>show passwords</a></div>
                        <fieldset className='mb-3'>
                            <div className="mb-3">
                                <label htmlFor="passwordCurrent" className="form-label">Current Password</label>
                                <input type="password" className="form-control" id="passwordCurrent" aria-describedby="passwordCurrent" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="passwordNew" className="form-label">New Password</label>
                                <input type="password" className="form-control" id="passwordNew" aria-describedby="passwordNew" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="passwordConfirm" className="form-label">Confirm New Password</label>
                                <input type="password" className="form-control" id="passwordConfirm" aria-describedby="passwordConfirm" />
                            </div>

                            <div className="text-end">
                                <button type="submit" className="btn btn-primary w-100">Update Password</button>
                            </div>
                        </fieldset>
                    </form>
                </div>}
            </div>
        </div>
    )
}