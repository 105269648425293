// libs
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { add, sub, isBefore, isAfter, format } from 'date-fns'

// app
import { client } from '../../api/client'
import { URL, SHEET, TRIP, cell } from "../../helpers/smartsheet-api"
import 'regenerator-runtime'


const _RANGE = {
    currWeek: 'Current Week',
    prevWeek: 'Previous Week',
    currMonth: 'Current Month',
    custom: 'Custom'
}


// our own custom async thunks for working with AWS cognito 
const _fetchMileage = createAsyncThunk(
    'mileage/fetch',
    // first param is the object sent to the _fetchMileage() method
    async ({ userName }) => {
        console.log(`FETCH mileage for ${userName ? userName : 'ALL'}`,)
        const res = await client.get(URL + "/" + SHEET.mileage)
        // append our custom piece of data (note the "_" prefix)
        // need to pass this from
        // [ 1 ] our dispatch() call in the component -->
        // [ 2 ] this asyncThunk action creator -->
        // [ 3 ] our async case statement (ie. _fetchMileage.fulfilled )
        return { ...res, ...{ _userName: userName } }
    }
)

const _postMileage = createAsyncThunk(
    'mileage/create',
    // first param is the object sent to the _postMileage() method
    async ({ toTop, toBottom, cells }, { rejectWithValue }) => {
        try {
            const res = await client.post(
                URL + "/" + SHEET.mileage + "/rows",
                {
                    toBottom: toBottom || null,
                    toTop: toTop || null,
                    cells: cells
                }
            )
            return res
        } catch (err) {
            //https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
            return rejectWithValue(err)
        }
    }
)


const _putMileageUpdate = createAsyncThunk(
    'mileage/update',

    // first param is the object sent to the _putMileageUpdate() method
    async ({ id, cells }, { rejectWithValue }) => {
        try {
            const res = await client.put(
                URL + "/" + SHEET.mileage + "/rows",
                {
                    id: id,
                    cells: cells
                }
            )
            return res
        } catch (err) {
            //https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
            return rejectWithValue(err)
        }
    }
)

// ---
// DATA
// ---
const _normalizeData = payload => {
    return payload.rows.reduce((prev, row, idx) => {
        prev.allIds.push(row.id)
        prev.byId[row.id] = row
        return prev
    }, { columnIds: payload.columns.map(col => { return col.id }), allIds: [], byId: {} })
}

// this creates a block of empty objects with the row id as the key
const _initializeSession = payload => {
    // we need a "0" id for new record creation (ie. _putMileageUpdate)
    // where else can we track status of this request (pending, fullfilled, rejected)
    payload.rows.push({ id: 0 })
    return payload.rows.reduce((prev, row, idx) => {
        prev.byId[row.id] = {
            data: {},
            // @todo: better name for this?  how to track interaction with specific actions for an individual row (ie. update, etc)
            api: {
                status: '',
                error: ''
            }
        }
        return prev
    }, { byId: {} })
}

const _filterData = (payload, params) => {
    const { userName = '', dateRange = '', dateStart = '', dateEnd = '' } = params || {}
    console.log(`FILTER mileage for ${userName ? userName : 'ALL'}${dateRange ? ' for ' + dateRange : ''}`)

    // -- date ranges
    const _today = new Date()
    const _currWeekStart = sub(_today, { days: (_today.getDay() > 0 ? _today.getDay() : 0) })
    const _currMonth = _today.getMonth() + 1
    let _dateStart = dateStart !== '' ? new Date(dateStart + "T00:00:00") : ''
    let _dateEnd = dateEnd !== '' ? new Date(dateEnd + "T00:00:00") : ''

    // NOTE: we are adding an extra day on either side of _dateStart and _dateEnd in order to use isAfter() and isBefore() functions below
    switch (dateRange) {
        case _RANGE.currMonth:
            _dateStart = sub(new Date(_today.getFullYear(), _currMonth - 1), { days: 1 })
            _dateEnd = add(add(_dateStart, { days: 1 }), { months: 1 })
            break
        case _RANGE.prevWeek:
            _dateStart = sub(sub(_currWeekStart, { weeks: 1 }), { days: 1 })
            _dateEnd = add(_dateStart, { days: 8 })
            break
        case _RANGE.custom:
            break
        case _RANGE.currWeek:
        default:
            _dateStart = sub(_currWeekStart, { days: 1 })
            _dateEnd = add(_dateStart, { days: 8 })
            break
    }

    // NOTE: takes normalized data, so must first convert to an array for sorting and filtering
    const arr = payload.allIds.map(id => { return payload.byId[String(id)] })
    return arr.reduce((prev, row, idx) => {

        // [ 1 ] filter by current user
        if (cell(row, TRIP.user) === userName || userName === '') {

            // [ 1 A ] breakout open vs closed trips
            if (cell(row, TRIP.tripTotal) && cell(row, TRIP.tripTotal) !== '') prev.closed.push(row)
            else prev.open.push(row)

            // [ 1 B ] sort (CLOSED trips only)
            prev.closed.sort((a, b) => {
                return cell(a, TRIP.tripDate) > cell(b, TRIP.tripDate) ? -1 : 1
            })
        }

        // [ 2 ] filter by date range
        if (dateRange) {
            prev.open = prev.open.filter(trip => {
                // date-fns methods require a Date object 
                const tripDate = new Date(cell(trip, TRIP.tripDate) + "T00:00:00")
                return isAfter(tripDate, _dateStart) && isBefore(tripDate, _dateEnd)
            })
            prev.closed = prev.closed.filter(trip => {
                const tripDate = new Date(cell(trip, TRIP.tripDate) + "T00:00:00")
                return isAfter(tripDate, _dateStart) && isBefore(tripDate, _dateEnd)
            })

            // [ 2 B ] force sort by name when doing a date range
            prev.open.sort((a, b) => { return cell(a, TRIP.user) > cell(b, TRIP.user) ? -1 : 1 })
            prev.closed.sort((a, b) => { return cell(a, TRIP.user) > cell(b, TRIP.user) ? -1 : 1 })
        }

        return prev
    }, {
        sort: payload.sort || 'tripDate',
        dateRange: { name: dateRange, dateStart: format(add(_dateStart, { days: 1 }), 'MMM dd, yyyy'), dateEnd: format(sub(_dateEnd, { days: 1 }), 'MMM dd, yyyy') },
        fiteredBy: userName,
        open: [],
        closed: []
    })

}


// ---
// STATE
// ---
const initialState = {
    // api is loaded data
    api: {
        status: '',
        // column IDs from the sheet level in order to enter a new record
        // NOTE: this is not needed for receiving notices, because records are added via smartsheet form
        columnIds: [],
        allIds: [],
        byId: {}
    },
    // @todo: a standard session object (errors, status, data, etc)
    session: { byId: {} },
    filtered: {
        sort: '',
        dateRange: { name: '' },
        filteredBy: '',
        // NOTE: hardcoding these filtering options here is a tradeoff
        // -- these may need to be added to or replaced in the future
        // -- doing this, because we want React to automatically update display based on how we manipulate
        open: [],
        closed: []
    }
}



// ---
// SLICE
// ---
export const mileageSlice = createSlice({
    name: 'mileage',

    initialState,

    // reducers take current state and action and return a new state
    reducers: {

        filterMileageData(state, action) {
            // when calling _filterData() as a reducer, we need to use a {payload, params} object
            state.filtered = _filterData(
                // - payload
                {
                    // redux toolkit function for getting state instead of Proxy
                    allIds: current(state.api.allIds),
                    byId: current(state.api.byId)
                },
                // - params
                {
                    userName: action.payload.userName,
                    dateRange: action.payload.dateRange,
                    dateStart: action.payload.dateStart,
                    dateEnd: action.payload.dateEnd
                }
            )
        },

        updateSession(state, action) {
            state.session.byId[action.payload.id] = {
                ...state.session.byId[action.payload.id],
                //status: action.payload.status,
                //errors: action.payload.errors,
                data: action.payload.data
            }
        }

    },

    // createAsyncThunk defines actions outside of createSlice() call,
    // extraReducers allows us to listen to other action types defined elsewhere
    extraReducers: builder => {
        builder
            // FETCH ---
            .addCase(_fetchMileage.pending, (state, action) => { state.api.status = 'pending' })
            .addCase(_fetchMileage.fulfilled, (state, action) => {
                // core row data
                const normalized = _normalizeData(action.payload)
                state.api = { status: 'fulfilled', ...normalized }
                state.filtered = _filterData(normalized, { userName: action.payload._userName })
                state.session = _initializeSession(action.payload)
                // if an open trip exists, put it into session
                if (state.filtered.open.length > 0) state.session.byId[state.filtered.open[0].id].data = state.filtered.open[0]
            })
            .addCase(_fetchMileage.rejected, (state, action) => { state.api.status = 'rejected' })
            // -- POST
            // creating a new record, hardcoded to 0
            .addCase(_postMileage.pending, (state, action) => { state.session.byId[0].api.status = 'pending' })
            .addCase(_postMileage.fulfilled, (state, action) => { state.session.byId[0].api.status = 'fulfilled' })
            .addCase(_postMileage.rejected, (state, action) => {
                state.session.byId[0].api.status = 'rejected'
                // using a string, can eventually use an object?
                state.session.byId[0].api.error = action.payload ? action.payload.error ? action.payload.error : action.payload : "Unable to update trip.  Unknown error"
            })
            // -- PUT
            .addCase(_putMileageUpdate.pending, (state, action) => { state.session.byId[action.meta.arg.id].api.status = 'pending' })
            .addCase(_putMileageUpdate.fulfilled, (state, action) => { state.session.byId[action.meta.arg.id].api.status = 'fulfilled' })
            .addCase(_putMileageUpdate.rejected, (state, action) => {
                state.session.byId[action.meta.arg.id].api.status = 'rejected'
                // using a string, can eventually use an object?
                state.session.byId[action.meta.arg.id].api.error = action.payload ? action.payload.error ? action.payload.error : action.payload : "Unable to update trip.  Unknown error"
            })
    }

})

// Action creators are generated for each case reducer function
// "createSlice will automatically generate action creators" - https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
export const { updateSession, filterMileageData } = mileageSlice.actions
export const fetchMileage = _fetchMileage
export const updateMileage = _putMileageUpdate
export const addMileage = _postMileage
export const RANGE = _RANGE

export default mileageSlice.reducer
