// libs
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link, useOutletContext } from "react-router-dom"
import { format } from 'date-fns'

// app
import { MILL, cell, colId } from "../../helpers/smartsheet-api"
import { fetch, updateSession, update, fetchAttachment } from './millSheetsSlice'


export function MillSheet(props) {

    // remember all URL params are strings
    const params = useParams();
    const _millsheetId = parseInt(params.millSheetId)

    const _context = useOutletContext()

    // how to dispatch actions
    const dispatch = useDispatch()

    // get data from store
    const apiStatus = useSelector((state) => state.millSheets.api.status)
    const _sheetId = useSelector((state) => state.millSheets.api.id)
    const sheetRowData = useSelector((state) => state.millSheets.api.byId[_millsheetId])
    const _isCompleted = cell(sheetRowData, MILL.docStage) === 'complete' ? true : false
    const sheetSession = useSelector((state) => state.millSheets.session.byId[_millsheetId]) || { api: "" }
    const attachmentData = useSelector((state) => state.millSheets.attachmentsList)
    // remember attachmentData is structured as: { status: '', data: [] }
    const _attachmentsList = attachmentData.data.filter(att => { return att.parentType === "ROW" && att.parentId === _millsheetId }) || []
    const _attachment = useSelector((state) => state.millSheets.session.byId[_millsheetId] ? state.millSheets.session.byId[_millsheetId].attachments : {})

    // split list on ";" if exist
    const unFilteredWorkDescription = cell(sheetRowData, MILL.workDescription)
        ? cell(sheetRowData, MILL.workDescription).match(/;/ig)
            ? cell(sheetRowData, MILL.workDescription).split(";")
            : cell(sheetRowData, MILL.workDescription).match(/\n/ig)
                ? cell(sheetRowData, MILL.workDescription).split(/\n/)
                : [cell(sheetRowData, MILL.workDescription)]
        : []

    // need to clear out empty items in a seperate operation, because filter() creates a new array
    const workDescription = unFilteredWorkDescription.filter(item => { return item.trim() !== "" })


    // ------
    // FORM
    // ------
    const handleSubmit = evt => {

        evt.preventDefault()
        let errors = []

        // validation
        if (!evt.target.workComplete.checked && !evt.target.workCompletionIssue.checked) errors.push('Must confirm work is complete or there was a problem')
        if (evt.target.workCompletionIssue.checked && evt.target.comments.value.trim() === '') errors.push('If there is a problem with completing work, please provide comments')
        if (evt.target.dateCompleted.value.trim() === '') errors.push('Must enter a date completed')
        if (evt.target.completedBy.value.trim() === '') errors.push('Work must be completed by someone')
        if (workDescription.length > 0) {
            const unchecked = workDescription.filter((material, idx) => { return !evt.target[`material${idx}`].checked })
            if (unchecked.length > 0 && evt.target.workComplete.checked) errors.push('If there is missing work, please note there is a problem with completion')
        }

        dispatch(updateSession({
            id: _millsheetId,
            errors: errors,
            status: errors.length > 0 ? 'rejected' : 'pending',
            data: {
                // even if none checked reply "issue with delivery"
                issueWithCompletion: evt.target.workComplete.checked ? false : true,
                comments: evt.target.comments.value.trim(),
                dateCompleted: evt.target.dateCompleted.value.trim(),
                completedBy: evt.target.completedBy.value.trim()
            }
        }))

        if (errors.length == 0) {
            console.log('MILL | dispatch(update)', _millsheetId)
            dispatch(update({
                id: _millsheetId,
                cells: [{
                    columnId: colId(sheetRowData, MILL.docStage),
                    value: 'complete'
                },
                {
                    columnId: colId(sheetRowData, MILL.completeDate),
                    value: evt.target.dateCompleted.value.trim()
                },
                {
                    columnId: colId(sheetRowData, MILL.completeBy),
                    value: evt.target.completedBy.value.trim()
                },
                {
                    columnId: colId(sheetRowData, MILL.completeNotes),
                    value: evt.target.comments.value.trim()
                }]
            }))

        }

    }

    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // get any attachments

        // NOTE: if data has not been pulled (ie. !== fulfilled), then fetch
        // @todo: update this code to be more clean like MileageEntry.js (ie. _apiData.status vs apiStatus)
        apiStatus !== 'fulfilled' && dispatch(fetch())

        if (_attachmentsList.length > 0) {
            console.log(" -- MILLSHEET has attachments", _attachmentsList)
            // we pass in the notice (row) id, because we need it when managing the pending, fullfilled, rejected status
            // !! NOTE: if we ever wish to re-load an attachment we will need to manually reset the below status to '' again
            sheetSession.attachments.status === '' && dispatch(fetchAttachment({
                sheetId: _sheetId,
                millsheetId: _millsheetId,
                attachmentId: _attachmentsList[0].id
            }))
        }


        // NOTE the [] as the second param, this ensures we only run useEffect() once
    }, []);

    // ------
    // RENDER
    // ------
    return (
        <div>

            {/* SHEETDATA | can we re-use this */}
            {apiStatus === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading <strong>mill sheets</strong>...</div>}

            {/* DEFAULT ---------------- */}
            {(sheetSession.api.status === '' || sheetSession.api.status === 'rejected') && <div>
                <div className='p-2 bg-light'>
                    <Link
                        className='btn btn-dark btn-sm'
                        to='/mill-sheets'
                    > <i className='bi-chevron-left'></i> Back to list</Link>
                </div>

                {sheetSession.errors.length > 0 &&
                    <div className="mb-3 w-100 alert alert-danger" role="alert">
                        <h4 className="alert-heading"><i className='fs-2 bi-emoji-dizzy'></i> Please fix issues below.</h4>
                        <ul className='mb-0'>{sheetSession.errors.map((err, idx) => { return <li key={idx}>{err}</li> })}</ul>
                        <hr />
                        <pre className="text-muted fs-6 my-0" >
                            id: {_millsheetId}
                        </pre>
                    </div>
                }

                {sheetSession.api.status === 'rejected' && <div className="alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to save <strong>mill sheet</strong>.{sheetSession.api.error && <div className="fst-italic mt-2">{sheetSession.api.error}</div>}</div>}

                <div className='bg-white'>
                    <form onSubmit={handleSubmit} className="p-2">


                        <div className='mb-2 bg-white'>
                            <h3 className="fw-bold">{cell(sheetRowData, MILL.workSummary)}</h3>
                            {cell(sheetRowData, MILL.assignee) && cell(sheetRowData, MILL.assignee).trim() !== '' && <div className='text-dark'>{cell(sheetRowData, MILL.assignee)}</div>}
                        </div>

                        <table className="table table-sm table-borderless">
                            <tbody>
                                <tr className="border-top">
                                    <td className="label-data">JOB</td>
                                    <td>{cell(sheetRowData, MILL.formJob)}</td>
                                </tr>
                                <tr className="border-top">
                                    <td className="label-data">PM</td>
                                    <td>{cell(sheetRowData, MILL.projMgr)}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="label-data">Work Description</div>
                        <fieldset className="mb-3">
                            {_isCompleted || workDescription
                                .map((item, idx) => {
                                    return <div className="form-check" key={idx}>
                                        <input className="form-check-input" type="checkbox" value="" id={`material${idx}`} />
                                        <label className="form-check-label" htmlFor={`material${idx}`}>
                                            {item}
                                        </label>
                                    </div>
                                })
                            }

                            {_isCompleted && workDescription
                                .map((item, idx) => {
                                    return <div key={idx}>{item}</div>
                                })
                            }
                        </fieldset>

                        {/* because we use map() functions above, we dont need to check if there is an actual list vs an attachment :) */
                            _attachmentsList.length > 0 && <div>
                                {/* ATTACHMENT | can we re-use this */}
                                {_attachment.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading <strong>attachment</strong>...</div>}

                                {_attachment.status === 'fulfilled' && <div className="my-0">
                                    <a href={_attachment.data.url}><i className='bi-file-ruled'></i> {_attachment.data.name}</a>
                                    <p className="text-muted">link expires in {_attachment.data.urlExpiresInMillis / 100 / 60} minutes.</p>
                                </div>}

                                {_attachment.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to load <strong>attachment</strong>.</div>}
                            </div>}


                        <div className="label-data">Delivery Instructions</div>
                        <div className="mb-3">
                            {cell(sheetRowData, MILL.workDelivery) || <span className='text-muted'>No instructions given.</span>}
                        </div>

                        {_isCompleted || <div className="card bg-light">
                            <div className="card-body">
                                <fieldset className="mb-1">
                                    <div className="form-check">
                                        <input type="radio" name="radios" className="form-check-input" id="workComplete" />
                                        <label className="form-check-label" htmlFor="workComplete">All work is complete.</label>
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input type="radio" name="radios" className="form-check-input" id="workCompletionIssue" />
                                        <label className="form-check-label" htmlFor="workCompletionIssue">There is a problem with finishing work.</label>
                                    </div>
                                    <div className="mb-3">
                                        <textarea className="form-control p-2" placeholder="Leave any comments here" id="comments" style={{ height: '100px' }}></textarea>
                                        <div id="comments" className="form-text">Comments are not required.</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="dateCompleted" className="form-label">Date Completed</label>
                                        <input type="date" className="form-control" id="dateCompleted" aria-describedby="dateCompleted" defaultValue={new Date().toISOString().split('T')[0]} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="completedBy" className="form-label">Completed By</label>
                                        <input type="text" className="form-control" id="completedBy" aria-describedby="completedBy" defaultValue={_context.user.name} />
                                    </div>
                                </fieldset>
                                <div className="d-flex gap-2 justify-content-evenly">
                                    <Link
                                        className='w-100 btn btn-outline-dark'
                                        to='/mill-sheets'
                                    > Cancel
                                    </Link>
                                    <button type="submit" className="w-100 btn btn-primary">Completed</button>
                                </div>
                            </div>
                        </div>}

                        {/* COMPLETED ---------------- */}
                        {_isCompleted && <div>
                            <hr />
                            <div className='text-bold mb-2'>Completion Information</div>
                            <table className="table table-sm table-borderless">
                                <tbody>
                                    <tr className="border-top">
                                        <td className="label-data">DATE COMPLETED</td>
                                        <td>{format(new Date(cell(sheetRowData, MILL.completeDate) + "T00:00:00"), "eee, LLL d")}</td>
                                    </tr>
                                    <tr className="border-top">
                                        <td className="label-data">COMPLETED BY</td>
                                        <td className="">{cell(sheetRowData, MILL.completeBy)}</td>
                                    </tr>
                                    {cell(sheetRowData, MILL.completeNotes) && <tr className="border-top">
                                        <td className="label-data">COMMENTS</td>
                                        <td className="fst-italic">{cell(sheetRowData, MILL.completeNotes)}</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>}
                    </form>

                    {/* METADATA ---------------- */}
                    <div className='p-2 mt-2'>
                        <div className='text-bold mb-2'>Original Request</div>
                        <table className="table table-sm table-borderless">
                            <tbody>
                                <tr className="border-top">
                                    <td className="label-data">ORDER DATE</td>
                                    <td>{format(new Date(cell(sheetRowData, MILL.workOrdered) + "T00:00:00"), "eee, LLL d")}</td>

                                </tr>
                                <tr className="border-top">
                                    <td className="label-data">ORDERED BY</td>
                                    <td>{cell(sheetRowData, MILL.orderBy)}</td>
                                </tr>
                                {cell(sheetRowData, MILL.completeTarget) && <tr className="border-top">
                                    <td className="label-data">EXPECTED BY</td>
                                    <td>{format(new Date(cell(sheetRowData, MILL.completeTarget) + "T00:00:00"), "eee, LLL d")}</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}

            {/* PENDING ---------------- */
                sheetSession.api.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert">
                    <h4 className="alert-heading"><i className='bi-cloud-arrow-down'></i> Saving mill sheet...</h4>
                    <p>Completed by <strong>{sheetSession.data.completedBy}</strong> on <strong>{sheetSession.data.dateCompleted}</strong>.</p>
                </div>}

            {/* FULFILLED ---------------- */
                sheetSession.api.status === 'fulfilled' && <div>
                    <div className="my-0 w-100 alert alert-success" role="alert">
                        <h4 className="alert-heading"><i className='bi-cloud-check'></i> Mill sheet updated.</h4>
                        <p>Completed by <strong>{sheetSession.data.completedBy}</strong> on <strong>{sheetSession.data.dateCompleted}</strong>.</p>
                    </div>
                    <Link
                        className='mt-4 btn btn-success'
                        to='/mill-sheets?millsheetIsComplete=true'
                    > <i className='bi-chevron-left'></i> Back to list</Link>
                </div>
            }

        </div>
    )
}