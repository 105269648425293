
export const MILES = 'miles_summary'
export const TALKS = 'toolbox_talks'
export const BETA = 'beta'

const _users = {
    // use of brackets allows us to evaluate constant vs. assign a new key without it
    [MILES]: [
        'paulas@scpb.com',
        'tjb@scpb.com',
        'josh@scpb.com',
        'ken@scpb.com'
    ],
    [TALKS]: [
        'tjb@scpb.com',
        'elib@scpb.com',
        'codyd@scpb.com',
    ],
    [BETA]: [
        'tjb@scpb.com'
    ]
}


export const access = (asset, email) => {
    if (asset === undefined) return false
    if (email === undefined) return false
    return _users[asset] && _users[asset].includes(email) ? true : false
}