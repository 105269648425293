// libs
import React from "react"
import { Link } from "react-router-dom";

// app
import { getLocal, setLocal } from "../helpers/localstorage"
import { access, MILES, TALKS } from "../features/Account/Permissions";

const Home = (props) => {

    // @todo: this seems like too much for a simple check?
    // should the localstorage methods NOT throw errors?
    let _haveReadAnnounce = getLocal("haveReadAnnounce") instanceof Error ? false : getLocal("haveReadAnnounce")

    const markAnnounceRead = evt => {
        evt.preventDefault()
        _haveReadAnnounce = true
        setLocal("haveReadAnnounce", true)
    }

    return (
        <div className="p-3">

            {false && !_haveReadAnnounce && <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading">New Stuff! <span className="text-secondary fs-6">ver 2.1.3</span></h6>
                <ul>
                    <li>mileage entry only shows last 7 days of previous trips</li>
                    <li>trips are defaulted as to/from home</li>
                </ul>
                <hr />
                <p className="mb-0"><a href="" onClick={markAnnounceRead}>Mark as read</a></p>
            </div>}


            <div className="card mb-2">
                <div className="card-body">
                    <h6 className="card-title">Material / Shipping</h6>
                    <div className="row">
                        <div className="col">
                            <Link className="btn btn-lg btn-primary btn-block w-100" aria-current="page" to="/receiving"><i className='bi-truck fs-1'></i><br />Receive</Link>
                        </div>
                        <div className="col">
                            <a className="mb-2 btn-lg btn btn-outline-primary btn-block w-100" href="https://coda.io/form/NEW-Order_dBzZNl_W5Cx"><i className='bi-plus-circle fs-1'></i><br />Enter Order</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-2">
                <div className="card-body">
                    <h6 className="card-title">Mill Sheets</h6>
                    <div className="row">
                        <div className="col">
                            <Link className="btn btn-lg btn-primary btn-block w-100" aria-current="page" to="/mill-sheets"><i className='bi-clipboard-check fs-1'></i><br />Complete</Link>
                        </div>
                        <div className="col">
                            <a className="mb-2 btn-lg btn btn-outline-primary btn-block w-100" href="https://app.smartsheet.com/b/form/e0d4ee2b6f3640428780c0681b9a6d82"><i className='bi-plus-circle fs-1'></i><br />New Milling</a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card mb-2">
                <div className="card-body">
                    <h6 className="card-title">Mileage</h6>
                    <div className="row">
                        <div className="col">
                            <Link className="btn btn-lg btn-primary btn-block w-100" aria-current="page" to="/mileage"><i className='bi-geo-alt-fill fs-1'></i><br />Record Trip</Link>
                        </div>

                        {props && props.user && props.user.email && access(MILES, props.user.email) &&
                            <div className="col">
                                <Link className="btn btn-lg btn-primary btn-block w-100" aria-current="page" to="/mileage/summaries"><i className='bi-clipboard-data fs-1'></i><br />Trip Reports</Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
            
            <div className="card mb-2">
                <div className="card-body">
                    <h6 className="card-title">Scheduling</h6>
                    <div className="row">
                        <div className="col">
                            <Link className="btn btn-lg btn-primary btn-block w-100" aria-current="page" to="/schedule"><i className='bi-calendar3 fs-1'></i><br />Crew Schedules</Link>
                        </div>

                    </div>
                </div>
            </div>
        
            <div className="card mb-2">
                <div className="card-body">
                    <h6 className="card-title">Safety</h6>
                    <div className="row">
                        <div className="col">
                            <Link className="btn btn-lg btn-primary btn-block w-100" aria-current="page" to="/toolbox-talks"><i className='bi-tools fs-1'></i><br />Toolbox Talk</Link>
                        </div>

                    </div>
                </div>
            </div>
            {/* <Link className="mb-2 btn btn-secondary btn-block w-100" aria-current="page" to="/masterformat">MasterFormat</Link> */}
            {/* <Link className="mt-4 btn btn-secondary btn-block w-100" aria-current="page" to="/jobs">Jobs</Link> */}

        </div>
    )
}

export default Home