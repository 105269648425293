// libs
import React, { useEffect } from 'react'

// app
import { Header } from "./Header"
import Login from "../features/Account/Login"

const Page = (props) => {

    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "SCPB" + (props.title ? " | " + props.title : "")
        // because we have a value in the following array: `[props.title]`, this only runs when it changes
        // this is why we may need to execute another `window.scrollTo(0,0)` in Material.js (detail page) because it uses the same page title
        // @todo: pass specific material title here as a prop and remove `window.scrollTo(0,0)` from Material.js
    }, [props.title]);

    const requireAuth = props.user.noAuth ? false : true

    return (
        <div> 
            <Header
                title={props.title}
                user={props.user}
            />
            <div className="container">
                {(!requireAuth || props.user.loggedIn) && props.children}
                {(requireAuth && !props.user.loggedIn) && <Login />}
                {/* <Link className="mt-4 btn btn-outline-dark btn-lg btn-block w-100" aria-current="page" to="/jobs">Jobs</Link> */}
            </div>
        </div>
    )
}

export default Page