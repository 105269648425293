// https://github.com/thisismydesign/cra-typescript-starter/blob/c5aeb50a9cda064486453c3095b34a76b1080aaa/src/utils/localLog.ts

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const localLog = (...args) => {
    if (window.location.href.includes("localhost")) {
        // eslint-disable-next-line no-console
        console.log("→",...args)
    }
}

export default localLog